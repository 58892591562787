import { Component } from '@angular/core';
import { AuthenticationService } from '../../_services';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss']
})
export class LoginPageComponent {

  showHide: boolean = false;
  passReset: boolean = false;

  constructor(
    public auth: AuthenticationService
    ) {

  }

  toggleForms() {
    this.showHide = !this.showHide;
    this.auth.errorSubject.next({});
  }

  togglePassReset() {
    this.passReset = !this.passReset;
    this.auth.errorSubject.next({});
  }

}
