import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { Router } from "@angular/router";
import { config } from '../../../../../_shared/_configs/config';
import { combineLatest, Subscription } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { FilesService } from 'src/app/_shared/_components/files/files.service';
import { User } from '../../users/_models/user.model';
import { AuthenticationService } from 'src/app/auth/_services';
import { UsersService } from '../../users/_services/users.service';
import { BookingsService } from '../../bookings/_services/bookings.service';

@Injectable()
export class EventsService {

  entityId: string;

  authSubscription: Subscription;
  loggedInUser: any;

  constructor(
    public afs: AngularFirestore,
    public router: Router,
    private filesService: FilesService,
    private auth: AuthenticationService,
    private usersService: UsersService,
    private bookingsService: BookingsService
  ) {
    this.entityId = config.entityId;
    this.authSubscription = this.auth.user.subscribe(userDetails => {
      if (userDetails) {
        this.loggedInUser = userDetails;
      }
    });
  }

  getEvents() {
    const eventsCollection = this.afs.collection(`entities/${this.entityId}/events`, ref => ref.orderBy('created', 'desc'));
    return eventsCollection.valueChanges({ idField: 'id' });
  }

  getEventDetails(eventId) {
    const eventsDoc = this.afs.doc(`entities/${this.entityId}/events/${eventId}`);
    return eventsDoc.valueChanges();
  }

  addEvent(eventInfo) {

    const eventsCollection = this.afs.collection(`entities/${this.entityId}/events`);

    // SET PREFIX FOR REF NO
    const referencePrefix = eventInfo.title.substring(0, 3).toUpperCase();

    // SET ROOM DOC DATA
    let eventData = {
      active: true,
      created: new Date(),
      createdBy: this.loggedInUser.uid,
      createdByName: `${this.loggedInUser.firstname} ${this.loggedInUser.surname}`,
      title: eventInfo.title,
      description: eventInfo.description,
      availableSpaces: eventInfo.availableSpaces,
      bookedSpaces: 0,
      address: eventInfo.locationDetails.address,
      referencePrefix: referencePrefix,
      source: config.source,
      locationId: eventInfo.locationDetails.uid,
      locationName: eventInfo.locationDetails.name,
      locationRef: `entities/${this.entityId}/locations/${eventInfo.locationDetails.uid}`,
      uploadedPhoto: '',
      uploadedPhotoThumbnail: '',
      startDate: eventInfo.startDate,
      endDate: eventInfo.endDate,
      date: eventInfo.date,
      times: eventInfo.times,
      status: 2
    }
    return eventsCollection.add(eventData).then(ref => {

      const eventsDoc = this.afs.doc(`entities/${this.entityId}/events/${ref.id}`);

      return eventsDoc.set({ uid: ref.id }, { merge: true }).then(doc => {

          if (eventInfo.eventImage) {
            // SET REF PATH TO SAVE
            let saveRef = {
              dbPath: `entities/${config.entityId}/events/${ref.id}`,
              filePath: `entities/${config.entityId}/events/${ref.id}/${ref.id}_image`,
              uploadType: 'cropper',
              thumbSize: 350
            };
            const uploadFiles = this.filesService.handleDrop(eventInfo.eventImage, saveRef);
            return Promise.resolve(uploadFiles).then(() => {
              this.addEventToBookings(eventData, ref.id);
            });
          } else {
              this.addEventToBookings(eventData, ref.id);
          }

      });

    });

  }

  addEventToBookings(eventData, eventId) {

    const bookingData = {
      active: true,
      attending: true,
      price: 0,
      created: eventData.created,
      createdBy: eventData.createdBy,
      createdByName: eventData.createdByName,
      name: eventData.title,
      startDate: eventData.startDate,
      endDate: eventData.endDate,
      locationId: eventData.locationId,
      locationRef: eventData.locationRef,
      locationName: eventData.locationName,
      type: 'event',
      referencePrefix: 'EVENT-B-',
      typeId: eventId,
      typeRef: `entities/${config.entityId}/events/${eventId}`,
      entityId: config.entityId,
      source: eventData.source,
      status: 2
    }

    const addBooking = this.bookingsService.addBooking(bookingData);
    return Promise.resolve(addBooking);

  }

  updateEvent(data, uid) {
    const eventsDoc = this.afs.doc(`entities/${this.entityId}/events/${uid}`);
    return eventsDoc.set(data, { merge: true })
  }

  getEventUsers(eventId) {
    const eventUsersCollection = this.afs.collection(`entities/${this.entityId}/events/${eventId}/users`, ref => ref.where('attending', '==', true));
    return eventUsersCollection.valueChanges();
  }

  updateEventUser(eventId, userId, data) {
    const eventUsersDoc = this.afs.doc(`entities/${this.entityId}/events/${eventId}/users/${userId}`);
    return eventUsersDoc.set(data, { merge: true });
  }

}
