import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthenticationService } from 'src/app/auth/_services';
import { User } from '../../pages/wealth-spaces/users/_models/user.model';

@Component({
  selector: 'app-authorised-top-nav',
  templateUrl: './top-nav.component.html',
  styleUrls: ['./top-nav.component.scss']
})
export class TopNavComponent implements OnInit {

  // USER
  user: User;
  userSubscription: Subscription;
  userFirstname: string;

  constructor(
    public auth: AuthenticationService
    ) { }

  ngOnInit() {

    this.userSubscription = this.auth.user.subscribe(userDetails => {
      if (userDetails) {
        this.user = userDetails;
        const checkEmailAddress = this.user.email.split('@')[1];
        // this.clientsService.userDetails.next(this.user);
        this.userFirstname = this.user.firstname.charAt(0);
      }
    });
  }

  logout() {
    this.auth.signOut();
  }

}
