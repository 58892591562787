import { NgModule } from '@angular/core';
import { Route, RouterModule } from '@angular/router';
import { ThemeComponent } from './theme.component';
import { AuthGuard } from '../auth/_guards';

const themeRoutes: Route[] = [
  {
    path: '',
    component: ThemeComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        canActivate: [AuthGuard],
        loadChildren: './pages/pages.module#PagesModule'
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(themeRoutes)],
  exports: [RouterModule]
})
export class ThemeRoutingModule {

}
