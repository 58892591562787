import { LOCALE_ID, Inject, Injectable } from '@angular/core';
import { CalendarEventTitleFormatter, CalendarEvent } from 'angular-calendar';
import { DatePipe } from '@angular/common';

@Injectable()
export class CustomEventTitleFormatter extends CalendarEventTitleFormatter {

  constructor(@Inject(LOCALE_ID) private locale: string) {
    super();
  }

  // you can override any of the methods defined in the parent class

  month(event: any): string {
    return this.generateTitleInfo(event)
  }

  week(event: any): string {
    return this.generateTitleInfo(event)
  }

  day(event: any): string {
    return this.generateTitleInfo(event)
  }

  generateTitleInfo(event: any): string {
    let title = event.title;
    let cancelledClass = '';
    if (event.displayType === 'location' || event.displayType === 'meetingRoom') title = `${event.booking.user.firstname} ${event.booking.user.surname}`;
    if (event.booking.status === 3) cancelledClass = ` class="text-cancelled"`;
    if (event.booking.status === 3) title = title + ' - Cancelled';
    return `<span${cancelledClass}><b>
    ${new DatePipe(this.locale).transform(event.booking.startTime ? this.convertDate(event.booking.startTime) : event.start,'h:mm a',this.locale)} -
    ${new DatePipe(this.locale).transform(event.booking.endTime ? this.convertDate(event.booking.endTime) : event.end,'h:mm a',this.locale)}</b> -
    ${title}</span> <span class="text-primary small">(click to edit)</span>`;
  }

  convertDate(date) {
    let newdate = date;
    if (date['seconds']) {
      newdate = date['seconds'] * 1000;
    }
    return newdate;
  }
}
