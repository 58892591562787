import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { environment } from '../../../../environments/environment';
import { MapsSearchComponent } from './maps-search/maps-search.component';
import { AgmCoreModule } from '@agm/core';
import { FormsModule } from '@angular/forms';

@NgModule({
    imports: [
        CommonModule,
        AngularFireModule.initializeApp(environment.firebase),
        AngularFirestoreModule,
        AngularFireStorageModule,
        FormsModule,
        AgmCoreModule.forRoot({
          apiKey: 'AIzaSyDtzpkn_YCwOiDNLhP54Ye8tz1p352lW4k',
          libraries: ['places']
        }),
    ],
    declarations: [
      MapsSearchComponent
    ],
    providers: [],
    exports: [
      MapsSearchComponent
    ]
})
export class MapsModule { }
