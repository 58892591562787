import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/storage';
import { Subject } from 'rxjs/Subject';
import { Subscription } from 'rxjs/Subscription';
import { take, map } from 'rxjs/operators';
import * as _ from "lodash";
import * as firebase from 'firebase';
import { NgxPermissionsService } from 'ngx-permissions';
import { Entity } from '../_models/entities.model';
import { config } from '../_configs/config';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { AuthenticationService } from '../../auth/_services';

@Injectable()
export class EntitiesService {

  entityId: string;

  // ENTITY DETAILS
  public adminEntityDetails = new BehaviorSubject<Entity>({});
  public currentAdminEntity = this.adminEntityDetails.asObservable();

  entitiesCollection: AngularFirestoreCollection<Entity>;

  loggedInUser: string;
  permissions: any;
  permissionsList: any;
  userPermissions: any;
  authSubscription: Subscription;


  constructor(
    public afStore: AngularFirestore,
    private auth: AuthenticationService,
    private permissionsService: NgxPermissionsService
  ) {
    this.entityId = config.entityId;
    this.authSubscription = this.auth.user.subscribe(userDetails => {
      if (userDetails) {
        this.loggedInUser = userDetails.uid;
        // FETCH ENTITIES ASSOCIATED TO USER
        this.entitiesCollection = this.afStore.collection(`users/${this.loggedInUser}/entities`, ref => ref.orderBy('name', 'asc'));
      }
    });
  }


  fetchEntityDetails() {
    const entityDoc = this.afStore.doc(`entities/${this.entityId}`);
    return entityDoc.valueChanges();
  }

  getEntityStatistics() {
    const allUsers = this.afStore.collection(`/entities/${config.entityId}/users`).valueChanges();
    const allLocations = this.afStore.collection(`/entities/${config.entityId}/locations/`).valueChanges();
    const allTeams = this.afStore.collection(`/entities/${config.entityId}/teams/`).valueChanges();
    const unverifiedLocations = this.afStore.collection(`/entities/${config.entityId}/locations/`, ref => ref.where('verified', '==', false)).valueChanges();
    const unverifiedTeams = this.afStore.collection(`/entities/${config.entityId}/teams/`, ref => ref.where('verified', '==', false)).valueChanges();
    const activeUsers = this.afStore.collection(`entities/${config.entityId}/users`, ref => ref.where('active', '==', true).where('status', '==', 1)).valueChanges();
    const unverifiedUsers = this.afStore.collection(`userRefs`, ref => ref.where('verified', '==', false)).valueChanges();
    const activeLocations = this.afStore.collection(`/entities/${config.entityId}/locations/`, ref => ref.where('status', '==', 1)).valueChanges();
    const activeTeams = this.afStore.collection(`/entities/${config.entityId}/teams/`, ref => ref.where('status', '==', 1)).valueChanges();
    const allBookings = this.afStore.collection(`/entities/${config.entityId}/bookings`).valueChanges();
    const activeBookings = this.afStore.collection(`/entities/${config.entityId}/bookings/`, ref => ref.where('status', '==', 2)).valueChanges();
    const allEvents = this.afStore.collection(`/entities/${config.entityId}/events`).valueChanges();
    const allMeetingRooms = this.afStore.collection(`/entities/${config.entityId}/meetingRooms`).valueChanges();
    const allSubscriptions = this.afStore.doc(`/entities/${config.entityId}`).valueChanges();

    return combineLatest([allUsers, allLocations, allTeams, unverifiedLocations, unverifiedTeams, activeUsers, unverifiedUsers, activeLocations, allBookings, activeBookings, allEvents, allMeetingRooms, allSubscriptions, activeTeams]).pipe(
      map((dataArrays: any) => {
        const entityStats = {
          users: dataArrays[0].length,
          locations: dataArrays[1].length,
          teams: dataArrays[2].length,
          unverifiedLocations: dataArrays[3].length,
          unverifiedTeams: dataArrays[4].length,
          activeUsers: dataArrays[5].length,
          unverifiedUsers: dataArrays[6].length,
          activeLocations: dataArrays[7].length,
          allBookings: dataArrays[8].length,
          activeBookings: dataArrays[9],
          allEvents: dataArrays[10].length,
          allMeetingRooms: dataArrays[11].length,
          allSubscriptions : dataArrays[12],
          activeTeams : dataArrays[13].length
        }
        return entityStats;
      })
    );
  }

  getUserForEntity(userID: string) {
    return this.afStore.doc(`entities/${this.entityId}/users/${userID}`).valueChanges();
  }

  // PERMISSIONS
  getUserPermissionsForEntity(userId: string) {
    return this.afStore.doc(`/users/${userId}/entities/${this.entityId}`).valueChanges();
  }

  getUserPermissionsForEntitySubscription(userId: string) {
    return this.afStore.doc(`/users/${userId}/entities/${this.entityId}`).valueChanges();
  }

  updateMeetingDates(dates) {
    return this.afStore.doc(`entities/${this.entityId}`).update({
      previousAgmDate: dates.lastAgm,
      agmDate: dates.nextAgm,
      previousExecutiveMeetingDate: dates.lastExec,
      executiveMeetingDate: dates.nextExec
    });
  }
}
