import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { auth } from 'firebase/app';

import { AngularFireAuth } from "@angular/fire/auth";
import { AngularFirestore, AngularFirestoreDocument } from "@angular/fire/firestore";
import { Observable, of, BehaviorSubject } from "rxjs";
import { switchMap, take, map } from "rxjs/operators";
import { User } from '../../theme/pages/wealth-spaces/users/_models/user.model';
import { config } from '../../_shared/_configs/config';
import { environment } from 'src/environments/environment';

@Injectable()
export class AuthenticationService {

    entityId: string;
    entityName: string;

    user: Observable<User>;
    userDetails: User;
    public userId: string;
    public userName: string;
    emailVerified: boolean = false;

    public errorSubject = new BehaviorSubject<any>({});
    public authErrors = this.errorSubject.asObservable();

    constructor(
      private afAuth: AngularFireAuth,
      private afs: AngularFirestore,
      private router: Router
    ) {

        this.entityId = config.entityId;
        this.entityName = config.entityName;

        this.user = this.afAuth.authState.pipe(switchMap(user => {
            if (user) {
                let updateUser = false;
                if (!this.userDetails) {
                    updateUser = true;
                } else if (this.userDetails.email !== user.email) {
                    updateUser = true;
                }

                if (updateUser) {
                    const userDoc = this.afs.collection("userRefs").doc<User>(user.uid).ref;
                    userDoc.get().then(doc => {
                        if (doc.exists) {
                            this.userDetails = doc.data();
                            this.userId = this.userDetails.uid;
                            sessionStorage.setItem("userId", this.userId);
                            sessionStorage.setItem("userFirstname", this.userDetails.firstname);
                            sessionStorage.setItem("userSurname", this.userDetails.surname);
                        }
                    });
                }
                return this.afs.doc<User>(`userRefs/${user.uid}`).valueChanges();
            } else {
                return of(null);
            }
        }));
    }

    googleLogin() {
        const provider = new auth.GoogleAuthProvider();
        return this.oAuthLogin(provider, 'google');
    }

    facebookLogin() {
        const provider = new auth.FacebookAuthProvider();
        return this.oAuthLogin(provider, 'facebook');
    }

    private oAuthLogin(provider, type) {
        return this.afAuth.auth.signInWithPopup(provider).then((credential: any) => {
            // SET USER FIRSTNAME AND SURNAME
            if (type === 'google') {
                credential.user.firstname = credential.additionalUserInfo.profile.given_name;
                credential.user.surname = credential.additionalUserInfo.profile.family_name;
            }
            if (type === 'facebook') {
                credential.user.firstname = credential.additionalUserInfo.profile.first_name;
                credential.user.surname = credential.additionalUserInfo.profile.last_name;
            }

            const userData = {
                firstname: credential.user.firstname,
                surname: credential.user.surname,
                email: credential.user.email.toLowerCase(),
                uid: credential.user.uid,
                photoURL: credential.user.photoURL,
                contactNumber: credential.user.phoneNumber,
                permissions: ['user'],
                signInMethod: type
            }

            const userRef = this.afs.doc(`/public/registeredUsers/list/${credential.user.uid}`);

            return userRef
                .snapshotChanges()
                .pipe(take(1))
                .toPromise()
                .then(snap => {
                    if (!snap.payload.data()) {
                        // IF USER DOES NOT EXIST CREATE USER WITH GOOGLE DETAILS
                        return this.createUserData(userData, true);
                    } else {

                        this.updateUserData(userData, snap.payload.data(), 'social');
                    }
                })
                .catch(error => {
                    console.log("User does not exist", error);
                    // IF USER DOES NOT EXIST CREATE USER WITH GOOGLE DETAILS
                    return this.createUserData(userData, true);
                });
        }).catch(error => {
            return this.errorSubject.next(error);
        });
    }

    checkIfUserRegistered(uid: string) {
        let collref = this.afs.collection('/public/registeredUsers/list').ref;
        let queryref = collref.where('uid', '==', uid);
        return queryref.get().then((snapShot) => {
            if (snapShot.empty) {
                return Promise.resolve('User not registered');
            }
            else {
                return Promise.reject('User registered');
            }
        });
    }

    emailSignUp(email, contactNumber, password, firstname, surname, permissions) {
        return this.afAuth.auth.createUserWithEmailAndPassword(email, password).then((user: any) => {
            const userData = {
                firstname: firstname,
                surname: surname,
                email: email.toLowerCase(),
                uid: user.user.uid,
                photoURL: user.user.photoURL,
                contactNumber: contactNumber,
                permissions: permissions,
                signInMethod: 'email'
            }
            return this.createUserData(userData, false);
        });
    }

    emailLogin(email, password) {
        return this.afAuth.auth.signInWithEmailAndPassword(email, password).then((credential: any) => {

          const userRef = this.afs.doc(`/public/registeredUsers/list/${credential.user.uid}`);
          return userRef
                .snapshotChanges()
                .pipe(take(1))
                .toPromise()
                .then(snap => {
                  const userData = {
                    uid: credential.user.uid,
                    socialLogin: false,
                    signInMethod: 'email'
                  }
                  this.updateUserData(userData, snap.payload.data(), 'email');
                });
        });
    }

    resetPassword(email) {
        const fbAuth = this.afAuth.auth;
        return fbAuth.sendPasswordResetEmail(email.toLowerCase());
    }

    sendEmailVerificationRequest(user) {

        return this.afs
            .collection(`pending`)
            .doc(user.firebaseId)
            .set({
                request: 'emailVerificationRequests',
                email: user.email.toLowerCase(),
                firstname: user.firstname,
                uid: user.firebaseId,
                entityId: this.entityId,
                entityName: this.entityName,
                source: config.source,
                websiteUrl: `${environment.websiteUrl}verification/`,
            });
    }

    sendEmailWelcomeMail(firebaseId) {
        return this.afs.collection(`pending`).add({
            request: 'emailWelcomeMail',
            firebaseId: firebaseId
        });
    }

    verifyUserEmail(firebaseId: string) {
        // CHECK USER UID EXISTS
        const userRef = this.afs.collection('public/registeredUsers/list').doc(firebaseId).ref;

        return userRef.get()
            .then(snap => {
                if (snap.exists === true) {
                    return this.afs.collection(`pending`).doc(firebaseId).set({
                        request: 'verifyUser',
                        verified: true,
                        source: config.source,
                        entityId: config.entityId
                    }, { merge: true })
                        .then(() => {
                            this.sendEmailWelcomeMail(firebaseId);
                            return Promise.resolve('User Verified');
                        });
                } else {
                    return Promise.reject('User could not be verified');
                }
            }).catch(error => {
              // const user = {
              //   firebaseId: firebaseId
              // };
              // return this.afs.collection(`pending`).add({
              //   request: 'addAuditTrail',
              //   category: 'users',
              //   description: error,
              //   source: config.source,
              //   entityId: config.entityId,
              //   user: user,
              //   type: 'auth',
              //   status: 'error'
              // });
                return Promise.reject('User could not be verified');
            });
    }

    veryifyNotFoundSoCheckFields(firebaseId) {
        // THE FIREBASE ID WAS NOT FOUND WHEN VERIFYING SO WE ARE NOW GOING TO DO ANOTHER CHECK TO SEE IF ANY
        // OF THE DOCUMENTS WITHIN THE USERREFS COLLECTION IF THE USER UID MATCHES AND IF IT MATHCES, RUN verifyUserEmail()
        // AGAIN WITH THE NEW ID
        const userRefRefs = this.afs.collection("public/registeredUsers/list").ref;

        return userRefRefs
            .where("uid", "==", firebaseId)
            .get()
            .then(snapshot => {
                if (!snapshot.empty) {
                    snapshot.forEach(doc => {
                        return this.verifyUserEmail(doc.id);
                    });
                }
            })
            .catch(err => {
                console.log("Error getting documents", err);
            });
    }

    private createUserData(user: User, socialLogin: boolean) {

        const pendingCreateUserDataRef = this.afs.doc(`pending/${user.uid}`);

        return pendingCreateUserDataRef.set({
            request: 'createUserData',
            user: user,
            socialLogin: socialLogin,
            entityId: this.entityId,
            entityName: this.entityName,
            source: config.source,
            websiteUrl: `${environment.websiteUrl}verification/`,
            generatedId: this.afs.createId()
        });
    }

    private updateUserData(user, userId, type) {
        console.log(type);
        const id = userId.uid;
        const userRef: AngularFirestoreDocument<User> = this.afs.doc(`users/${id}`);
        const userIDsRef: AngularFirestoreDocument<User> = this.afs.doc(`userRefs/${user.uid}`);

        if (user.contactNumber === null) {
          user.contactNumber = "";
        }

        let userDetailsResult;
        let userIDsResult;

        if (type === 'social') {

          userDetailsResult = userRef.set(
              {
                  uid: id,
                  firebaseId: user.uid,
                  email: user.email.toLowerCase(),
                  firstname: user.firstname,
                  surname: user.surname,
                  photoURL: user.photoURL,
                  contactNumber: user.contactNumber,
                  socialLogin: true,
                  signInMethod: user.signInMethod
              },
              { merge: true }
          );

          userIDsResult = userIDsRef.set(
              {
                  uid: id,
                  firebaseId: user.uid,
                  email: user.email.toLowerCase(),
                  firstname: user.firstname,
                  surname: user.surname,
                  photoURL: user.photoURL,
                  contactNumber: user.contactNumber,
                  socialLogin: true,
                  verified: true,
                  signInMethod: user.signInMethod
              },
              { merge: true }
          );

        } else {

          userDetailsResult = userRef.set({
            socialLogin: user.socialLogin,
            signInMethod: user.signInMethod
          }, { merge: true });

          userIDsResult = userIDsRef.set({
            socialLogin: user.socialLogin,
            signInMethod: user.signInMethod
          }, { merge: true });

        }

        return Promise.all([userDetailsResult, userIDsResult]);
    }

    updateUserPassword(password) {
        const currentUser = this.afAuth.auth.currentUser;
        return currentUser
            .updatePassword(password)
            .then(() => {
                // console.log("password successful");
            })
            .catch(error => {
                console.log(error);
                return error;
            });
    }

    updateUserEmail(userEmail) {
        const currentUser = this.afAuth.auth.currentUser;
        return currentUser.updateEmail(userEmail);
    }

    reAuthUser(email, password) {
        const currentUser = this.afAuth.auth.currentUser;
        const credential = auth.EmailAuthProvider.credential(email, password);
        return currentUser.reauthenticateAndRetrieveDataWithCredential(credential);
    }

    signOut() {
        this.afAuth.auth.signOut().then(() => {
            // this.updateOnlineStatus(this.userId, false);
            // window.location.reload();
            this.router.navigate(["/login"]);
        });
    }

    checkPendingUsersInvites(user: User) {
        let collref = this.afs.collection("pendingUserInvites").ref;
        let queryref = collref.where("email", "==", user.email);
        return queryref.get().then(snapShot => {
            if (snapShot.empty) {
                return Promise.reject("No pending invites");
            } else {
                return Promise.resolve(
                    snapShot.docs.map(documentSnapshot => {
                        return documentSnapshot.data();
                    })
                );
            }
        });
    }

    updateOnlineStatus(userId: string, status: boolean) {
        const userRef = this.afs.doc(`users/${userId}`);

        userRef.set(
            {
                online: status
            },
            { merge: true }
        );

        this.userId = userId;
    }

    setFirstPassword(password) {
        const user = this.afAuth.auth.currentUser;
        return user.updatePassword(password).catch((error) => {
            console.log("​setFirstPassword -> error", error)
        });
    }
}
