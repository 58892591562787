import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgxPermissionsModule } from 'ngx-permissions';
import { AppCoreModule } from 'src/app/_shared/app-core.module';
import { AsideNavComponent } from './aside-nav/aside-nav.component';
import { AsideNavTogglerComponent } from './aside-nav-toggler/aside-nav-toggler.component';
import { TopNavComponent } from './top-nav/top-nav.component';
import { FooterComponent } from './footer/footer.component';

@NgModule({
    declarations: [
      AsideNavComponent,
      AsideNavTogglerComponent,
      TopNavComponent,
      FooterComponent
    ],
    exports: [
      AsideNavComponent,
      AsideNavTogglerComponent,
      TopNavComponent,
      FooterComponent
    ],
    imports: [
      CommonModule,
      RouterModule,
      NgxPermissionsModule.forChild(),
      AppCoreModule
    ]
})
export class LayoutModule {
}
