import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl, ValidatorFn, FormArray, NgForm, FormControl } from '@angular/forms';
import { StorageService } from 'src/app/_shared/_services/storage.service';
import { Subject, Subscription } from 'rxjs';
import { packages } from '../../../../../theme/pages/wealth-spaces/products/_models/packages';
import { ProductsService } from 'src/app/theme/pages/wealth-spaces/products/_services/products.service';
import { SubscriptionsService } from 'src/app/theme/pages/wealth-spaces/subscriptions/_services/subscriptions.service';

@Component({
  selector: 'ws-package-select',
  templateUrl: './ws-package-select.component.html',
  styleUrls: ['../ws-products.scss']
})
export class WSPackageSelectComponent implements OnInit, OnDestroy {

  @Input() extraData: any;

  @Output() closeModal = new EventEmitter();

  packageForm: FormGroup;
  packages = packages;
  package: any;

  productsSubscription: Subscription;
  isLoading: boolean = false;
  products: any[];
  isSubmitting: boolean = false;
  activePackage: any[];


  modalSubscription: Subscription;

  constructor(
    private fb: FormBuilder,
    private productsService: ProductsService,
    private storageService: StorageService,
    private subscriptionsService: SubscriptionsService
  ) {
  }

  ngOnInit() {
    this.buildForm();
  }

  buildForm() {
    this.packageForm = this.fb.group({
      package: ['', [Validators.required]]
    });
  }

  packageSelect() {
    this.package = this.packageForm.get('package').value;
  }

  onProceed() {
    const values = this.packageForm.value;
    values.team = this.extraData.data.team;
    if (this.extraData.submit) {
      const modalData = {
        modalId: 'productModal',
        title: `${values.package.name} - ${values.package.refNo}`,
        type: 'productSteps',
        data: values
      }
      this.storageService.openModal(modalData);
    } else {
    }
  }

  ngOnDestroy() {
    // this.productsSubscription.unsubscribe();
    // this.modalSubscription.unsubscribe();
  }

}
