import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { Router } from "@angular/router";
import { config } from '../../../../../_shared/_configs/config';
import { Subscription } from 'rxjs';
import { AuthenticationService } from 'src/app/auth/_services';

declare var toastr: any;

@Injectable()
export class CouponsService {

  entityId: string;

  authSubscription: Subscription;
  loggedInUser: any;

  constructor(
    public afs: AngularFirestore,
    public router: Router,
    private auth: AuthenticationService,
  ) {
    this.entityId = config.entityId;
    this.authSubscription = this.auth.user.subscribe(userDetails => {
      if (userDetails) {
        this.loggedInUser = userDetails;
      }
    });
  }

  getCoupons() {
    const couponsCollection = this.afs.collection(`entities/${this.entityId}/coupons`, ref => ref.orderBy('created', 'desc'));
    return couponsCollection.valueChanges({ idField: 'id' });
  }

  addCoupon(coupon: any) {
    coupon.created = new Date();
    coupon.usedBy = [];
    const couponDoc = this.afs.collection(`entities/${this.entityId}/coupons`);
    return couponDoc.add(coupon);
  }

  getCoupon(id: string) {
    const couponDoc = this.afs.doc(`entities/${this.entityId}/coupons/${id}`);
    return couponDoc.valueChanges();
  }

  updateCoupon(id: string, coupon: any) {
    const couponDoc = this.afs.doc(`entities/${this.entityId}/coupons/${id}`)
    return couponDoc.set(coupon, { merge:true });
  }

}
