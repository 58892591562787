import { Component, OnInit } from '@angular/core';
import { AsideNavService } from '../_services/authorised-side-nav.service';

@Component({
  selector: 'app-aside-nav',
  templateUrl: './aside-nav.component.html',
  styleUrls: ['./aside-nav.component.scss']
})
export class AsideNavComponent implements OnInit {

  constructor(public sideNavService: AsideNavService) { }

  ngOnInit() {
  }

}
