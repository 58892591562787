import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ModalsComponent } from './modals.component';
import { ModalProductsComponent } from './modals-products/modal-products';
import { WealthSpacesCompModule } from '../wealthspaces/wealthspaces.module';
import { AppFormsModule } from '../forms/app-forms.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ModalBookingsComponent } from './modals-bookings/modal-bookings';

@NgModule({
  declarations: [
    ModalsComponent,
    ModalProductsComponent,
    ModalBookingsComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    WealthSpacesCompModule,
    AppFormsModule,
    NgbModule
  ],
  exports: [
    ModalsComponent,
    ModalProductsComponent,
    ModalBookingsComponent
  ],
  providers: []
})
export class ModalsModule {
}
