import { Message } from "./../_models/message";
import { Component, OnInit, OnDestroy, Input } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { Subscription } from "rxjs";

import { AuthenticationService } from "src/app/auth/_services";
import { MessagesService } from "../_services/messages.service";
import { config } from "../../../../_shared/_configs/config";
import { Conversation } from "./../_models/conversation";
import { Sender } from "./../_models/sender";

declare var $: any;

@Component({
  selector: "app-messages-recent",
  templateUrl: "./messages-recent.component.html",
  styleUrls: ["./messages-recent.component.scss"],
})
export class MessagesRecentComponent implements OnInit, OnDestroy {
  @Input() set conversationIdToLoadInput(conversationIdToLoad: string) {
    this.conversationIdToLoad = conversationIdToLoad;
  }
  @Input() set locationInput(location: any) {
    this.location = location;
  }
  location: any;
  messagesSubscription: Subscription;
  conversationsSubscription: Subscription;
  isLoading: boolean = false;
  conversationsList: any;
  messagesList: any;
  existingConversationsLookupList: any[] = [];
  conversationDetails: Conversation;
  conversationIdToLoad: string;
  userIdOfConversations: string;
  senderDetails: Sender;
  currentUserId: string;
  activeConversationId: string;
  messageObj: object;
  selected = '';

  constructor(
    public router: Router,
    private route: ActivatedRoute,
    private messagesService: MessagesService,
    private auth: AuthenticationService,
  ) {
    this.conversationDetails = new Conversation();
    this.senderDetails = new Sender();
  }

  ngOnInit() {
    this.currentUserId = this.auth.userId;

    //CHECK IF ITS THE WEALTHSPACES SUPPORT CHAT
    if (this.conversationIdToLoad) {
      if (this.conversationIdToLoad === "wealthspaces") {
        this.senderDetails.id = "wealthspaces";
        this.senderDetails.name = "Wealth Spaces";
        this.senderDetails.image = config.wealthSpacesIcon;
        this.userIdOfConversations = "wealthspaces";
      } else if (this.conversationIdToLoad === 'location') {
        this.senderDetails.id = this.location.refNo;
        this.senderDetails.name = this.location.name;
        this.senderDetails.image = (this.location.uploadedPhotoThumbnail && this.location.uploadedPhotoThumbnail !== '') ? this.location.uploadedPhotoThumbnail : config.wealthSpacesIcon;
        this.userIdOfConversations = this.location.refNo;
      } else {
        this.senderDetails.id = this.auth.userDetails.uid;
        this.senderDetails.name = this.auth.userDetails.firstname + " " + this.auth.userDetails.surname;
        this.senderDetails.image = (this.auth.userDetails.uploadedPhotoThumbnail) ? this.auth.userDetails.uploadedPhotoThumbnail : this.auth.userDetails.photoURL;
        this.userIdOfConversations = this.auth.userDetails.uid;
        this.activeConversationId = this.conversationIdToLoad;
      }
    } else {
      this.senderDetails.id = this.auth.userDetails.uid;
      this.senderDetails.name = this.auth.userDetails.firstname + " " + this.auth.userDetails.surname;
      this.senderDetails.image = (this.auth.userDetails.uploadedPhotoThumbnail) ? this.auth.userDetails.uploadedPhotoThumbnail : this.auth.userDetails.photoURL;
      this.userIdOfConversations = this.auth.userDetails.uid;
      this.activeConversationId = this.conversationIdToLoad;
    }

    this.conversationsSubscription = this.messagesService
      .getConversationsForUser(this.userIdOfConversations)
      .subscribe((conversations) => {
        if (conversations) {
          this.conversationsList = conversations;
          this.conversationsList.forEach((convo) => {
            this.existingConversationsLookupList.push(convo.id);
          });
        }
        if (
          this.conversationIdToLoad &&
          this.conversationIdToLoad !== "wealthspaces"
        ) {
          this.goToConversation((this.conversationIdToLoad !== 'location') ? this.conversationIdToLoad : this.location.refNo);
        }
      });
  }

  goToConversation(conversationId) {
    this.selected = conversationId;
    this.activeConversationId = conversationId;
    this.messagesList = [];
    if (this.existingConversationsLookupList.includes(conversationId)) {
      this.scrollToConversation(conversationId);
    } else {
      if (this.conversationIdToLoad !== "wealthspaces" && this.conversationIdToLoad !== "location") {
        this.messagesService
          .createConversation(
            this.userIdOfConversations,
            this.conversationIdToLoad
          )
          .then(() => {
            this.scrollToConversation(this.activeConversationId);
          });
      }
      //Create a conversation
    }
  }

  scrollToConversation(conversationId) {
    this.selected = conversationId;
    this.activeConversationId = conversationId;
    this.messagesSubscription = this.messagesService
      .getMessagesForConversation(
        this.userIdOfConversations,
        this.activeConversationId
      )
      .subscribe((messages) => {
        if (messages) {
          this.messagesList = messages;
          setTimeout(function () {
            if (messages.length > 0) {
              const lastMessage = document.getElementById(
                messages[messages.length - 1]["id"]
              );
              if (lastMessage) {
                lastMessage.scrollIntoView({block: "end"});
              }
            }
          }, 500);
        }
      });
  }

  addMessage(newMessageForm) {
    const messageObj = new Message();
    if (newMessageForm.message) {
      messageObj.text = newMessageForm.message;
      messageObj.created = new Date();
      messageObj.createdById = this.senderDetails.id;
      messageObj.createdByName = this.senderDetails.name;
      messageObj.createdByImage = this.senderDetails.image;
      messageObj.type = "text";
      this.messagesService
        .addMessageToConversation(
          this.userIdOfConversations,
          this.activeConversationId,
          Object.assign({}, messageObj)
        )
        .then(() => {
          newMessageForm.message = "";
        });
    }
  }

  ngOnDestroy() {
    if (this.messagesSubscription) {
      this.messagesSubscription.unsubscribe();
    }
    if (this.conversationsSubscription) {
      this.conversationsSubscription.unsubscribe();
    }
  }
}
