import { Component } from '@angular/core';
import { Router, ActivatedRoute, NavigationStart, NavigationEnd } from '@angular/router';

import { Observable } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  public state$: Observable<{ [key:string]: string }>;
  constructor(private router: Router) { }

  ngOnInit() {
    this.router.events.subscribe(route => {
      if (route instanceof NavigationStart) {
      }
      if (route instanceof NavigationEnd) {
      }
    });
  }
}
