import { Component, ComponentFactoryResolver, OnInit, ViewChild, ViewContainerRef, ViewEncapsulation, } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'app-auth',
    template: '<router-outlet></router-outlet>',
    encapsulation: ViewEncapsulation.None,
})

export class AuthComponent implements OnInit {

  returnUrl: string;

  constructor(
    private _router: Router,
    private _route: ActivatedRoute,
    ) { }

  ngOnInit() {
    // get return url from route parameters or default to '/'
    // this.returnUrl = this._route.snapshot.queryParams['returnUrl'] || '/';
    // this._router.navigate([this.returnUrl]);
  }

}
