import { config } from "../../../../_shared/_configs/config";

export class Message {
  id?: string;
  text?: string;
  created?: Date;
  type?: string;
  createdByName?: string;
  createdById?: string;
  createdByImage?: string;
  active?: boolean;

  constructor(
  ) { 
    this.text = 'Wealth Spaces';
    this.created = new Date;
    this.type = 'text';
    this.createdByName = 'Wealth Spaces';
    this.createdByImage = config.wealthSpacesIcon;
    this.createdById = "wealthspaces";
    this.active = true;
  }
}