import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { Router } from "@angular/router";
import { config } from '../../../../../_shared/_configs/config';
import { combineLatest, Subscription } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { FilesService } from 'src/app/_shared/_components/files/files.service';
import { User } from '../../users/_models/user.model';
import { AuthenticationService } from 'src/app/auth/_services';
import { UsersService } from '../../users/_services/users.service';

declare var toastr: any;

@Injectable()
export class PaymentsService {

  entityId: string;

  authSubscription: Subscription;
  loggedInUser: any;

  constructor(
    public afs: AngularFirestore,
    public router: Router,
    private filesService: FilesService,
    private auth: AuthenticationService,
    private usersService: UsersService
  ) {
    this.entityId = config.entityId;
    this.authSubscription = this.auth.user.subscribe(userDetails => {
      if (userDetails) {
        this.loggedInUser = userDetails;
      }
    });
  }

  getSplitPayments() {
    const paymentsCollection = this.afs.collection(`entities/${this.entityId}/payments`, ref => ref.where('active', '==', true).orderBy('created', 'desc'));
    return paymentsCollection.valueChanges({ idField: 'id' });
  }

  setPaid(id, values) {
    const paymentDoc = this.afs.doc(`entities/${this.entityId}/payments/${id}`);
    return paymentDoc.set(values, { merge: true });
  }

}
