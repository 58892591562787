import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { Router } from "@angular/router";
import { config } from '../../../../../_shared/_configs/config';
import { combineLatest, Subscription } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { FilesService } from 'src/app/_shared/_components/files/files.service';
import { User } from '../../users/_models/user.model';
import { AuthenticationService } from 'src/app/auth/_services';
import { UsersService } from '../../users/_services/users.service';

declare var toastr: any;

@Injectable()
export class MeetingRoomsService {

  entityId: string;

  authSubscription: Subscription;
  loggedInUser: any;

  constructor(
    public afs: AngularFirestore,
    public router: Router,
    private filesService: FilesService,
    private auth: AuthenticationService,
    private usersService: UsersService
  ) {
    this.entityId = config.entityId;
    this.authSubscription = this.auth.user.subscribe(userDetails => {
      if (userDetails) {
        this.loggedInUser = userDetails;
      }
    });
  }

  getMeetingRooms() {
    const roomsCollection = this.afs.collection(`entities/${this.entityId}/meetingRooms`, ref => ref.orderBy('name', 'asc'));
    return roomsCollection.valueChanges({ idField: 'id' });
  }

  getPublicMeetingRooms() {
    const roomsCollection = this.afs.collection(`public/${this.entityId}/meetingRooms`, ref => ref.orderBy('name', 'asc'));
    return roomsCollection.valueChanges({ idField: 'id' });
  }


  getLocationMeetingRooms(locationId) {
    const roomsCollection = this.afs.collection(`entities/${this.entityId}/locations/${locationId}/meetingRooms`, ref => ref.orderBy('name', 'asc'));
    return roomsCollection.valueChanges({ idField: 'id' });
  }

  getMeetingRoomDetails(roomId) {
    const getRoomDetails = this.afs.doc(`/entities/${config.entityId}/meetingRooms/${roomId}`).valueChanges();
    const getRoomAmenities = this.afs.doc(`/entities/${config.entityId}/meetingRooms/${roomId}/amenities/list`).valueChanges();
    const getRoomBusinessHours = this.afs.doc(`/entities/${config.entityId}/meetingRooms/${roomId}/businessHours/list`).valueChanges();
    const getRoomTypes = this.afs.doc(`/entities/${config.entityId}/meetingRooms/${roomId}/roomTypes/list`).valueChanges();
    const getRoomGallery = this.afs.collection(`/entities/${config.entityId}/meetingRooms/${roomId}/gallery`, ref => ref.orderBy('order', 'asc')).valueChanges({ idField: 'uid' });
    return combineLatest([getRoomDetails, getRoomAmenities, getRoomBusinessHours, getRoomTypes, getRoomGallery]).pipe(
      map((dataArrays) => {
        let locationData = {
          room: dataArrays[0],
          amenities: dataArrays[1],
          businessHours: dataArrays[2],
          roomTypes: dataArrays[3],
          gallery: dataArrays[4]
        };
        return locationData;
      })
    );
  }

  getMeetingRoomDetailsBySlug(roomSlug) {
    const getMeetingRoomId = this.afs.collection(`/entities/${config.entityId}/meetingRooms`, ref => ref.where('refNo', '==', roomSlug));
    return getMeetingRoomId.valueChanges({ idField: 'id' });
  }

  addMeetingRoom(roomInfo) {

    const roomsCollection = this.afs.collection(`entities/${this.entityId}/meetingRooms`);

    // SET VERIFIED ROOM
    let verified;
    if (config.source === 'admin') {
      verified = true;
    } else {
      verified = false;
    }

    // SET PREFIX FOR REF NO
    const referencePrefix = roomInfo.basicInfo.name.substring(0, 3).toUpperCase();

    // SET PREFIX FOR SLUG
    const slugPrefix = roomInfo.basicInfo.name.replace(/\s/g, '-').toLowerCase();

    // SET ROOM DOC DATA
    let roomData = {
      active: true,
      created: new Date(),
      createdBy: this.loggedInUser.uid,
      name: roomInfo.basicInfo.name,
      description: roomInfo.basicInfo.description,
      sqMetres: roomInfo.basicInfo.sqMetres,
      maxPeople: roomInfo.basicInfo.maxPeople,
      hourlyRate: roomInfo.basicInfo.hourlyRate,
      address: roomInfo.locationDetails.address,
      status: 0,
      referencePrefix: referencePrefix,
      source: config.source,
      verified: verified,
      locationId: roomInfo.locationDetails.uid,
      locationName: roomInfo.locationDetails.name,
      locationRef: `/entities/${this.entityId}/locations/${roomInfo.locationDetails.uid}`,
      featureImage: '',
      featureImageThumbnail: '',
      featureImageId: '',
      slugPrefix: slugPrefix,
      actualRate: roomInfo.basicInfo.actualRate
    }

    return roomsCollection.add(roomData).then(ref => {
      const roomsDoc = this.afs.doc(`entities/${this.entityId}/meetingRooms/${ref.id}`);
      const amenitiesDoc = this.afs.doc(`entities/${this.entityId}/meetingRooms/${ref.id}/amenities/list`);
      const businessHoursDoc = this.afs.doc(`entities/${this.entityId}/meetingRooms/${ref.id}/businessHours/list`);
      const roomTypesDoc = this.afs.doc(`entities/${this.entityId}/meetingRooms/${ref.id}/roomTypes/list`);
      const requestDoc = this.afs.doc(`pending/${ref.id}`);

      return roomsDoc.set({ uid: ref.id }, { merge: true }).then(() => {

        // SET AMENITIES
        const setAmenities = amenitiesDoc.set({ list: roomInfo.amenities }, { merge: true });

        // SET BUSINESS HOURS
        const setBusinessHours = businessHoursDoc.set({
          monday: roomInfo.businessHours.monday,
          tuesday: roomInfo.businessHours.tuesday,
          wednesday: roomInfo.businessHours.wednesday,
          thursday: roomInfo.businessHours.thursday,
          friday: roomInfo.businessHours.friday,
          saturday: roomInfo.businessHours.saturday,
          sunday: roomInfo.businessHours.sunday
        }, { merge: true });

        // SET ROOM TYPES
        const setRoomTypes = roomTypesDoc.set({ list: roomInfo.roomTypes }, { merge: true });

        return Promise.all([setAmenities, setBusinessHours, setRoomTypes]).then(() => {

          if (roomInfo.gallery) {
            const uploaGallery = this.handleGalleryImages(roomInfo.gallery, roomInfo.featuredImg, ref.id);
            return Promise.resolve(uploaGallery);
          }

        });

      });


    });

  }

  updateMeetingRoom(roomId, room, businessHours?, amenitiesList?, typesList?) {
    const roomsDoc = this.afs.doc(`entities/${this.entityId}/meetingRooms/${roomId}`);
    return roomsDoc
      .set(room, { merge: true })
      .then(() => {
        if (businessHours) {
          this.updateBusinessHours(roomId, businessHours);
        }
        if (amenitiesList) {
          this.updateAmenities(roomId, amenitiesList);
        }
        if (typesList) {
          this.updateRoomTypes(roomId, typesList);
        }
      })
      .catch(error => {

      });
  }

  updateBusinessHours(roomId, businessHours) {
    const businessHoursDoc = this.afs.doc(`entities/${this.entityId}/meetingRooms/${roomId}/businessHours/list`);
    return businessHoursDoc.set({
      monday: businessHours.monday,
      tuesday: businessHours.tuesday,
      wednesday: businessHours.wednesday,
      thursday: businessHours.thursday,
      friday: businessHours.friday,
      saturday: businessHours.saturday,
      sunday: businessHours.sunday
    }, { merge: true });
  }

  updateAmenities(roomId, amenitiesList) {
    const amenitiesDoc = this.afs.doc(`entities/${this.entityId}/meetingRooms/${roomId}/amenities/list`);
    return amenitiesDoc.set({ list: amenitiesList }, { merge: true });
  }

  updateRoomTypes(roomId, typesList) {
    const typesDoc = this.afs.doc(`entities/${this.entityId}/meetingRooms/${roomId}/roomTypes/list`);
    return typesDoc.set({ list: typesList }, { merge: true });
  }

  handleGalleryImages(imagesArray, featuredImg, roomId) {
    const roomPhotosArray: any = [];
    let pathInfo = [];
    if (imagesArray.length > 0) {
      return imagesArray.forEach((image, index, array) => {
        let imgInfo = {
          filePath: `entities/${config.entityId}/meetingRooms/${roomId}`,
          dbPath: `entities/${config.entityId}/meetingRooms/${roomId}/gallery`,
          order: image.order,
          tempId: image.tempId,
          feautureImgDoc: `entities/${config.entityId}/meetingRooms/${roomId}`
        };
        roomPhotosArray.push(image.file);
        pathInfo.push(imgInfo);
        if (roomPhotosArray.length === array.length && pathInfo.length == array.length) {
          return this.filesService.handleGalleryDrop(roomPhotosArray, pathInfo, featuredImg);
        } else {
          return Promise.resolve('Failed to add images ');
        }
      })
    }
  }

  updateGalleryImageOrder(roomId, image) {
    const imageDoc = this.afs.doc(`entities/${this.entityId}/meetingRooms/${roomId}/gallery/${image.uid}`);
    return imageDoc.set({ order: image.order }, { merge: true });
  }

  deleteGalleryImage(roomId, imageId) {
    const imageDoc = this.afs.doc(`entities/${this.entityId}/meetingRooms/${roomId}/gallery/${imageId}`);
    return imageDoc.delete();
  }

}
