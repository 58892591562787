import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { environment } from '../../../../../environments/environment';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { BookingsEditEventFormComponent } from './bookings-edit/events/bookings-edit-event.form';
import { BookingsEditRoomFormComponent } from './bookings-edit/meeting-rooms/bookings-edit-room.form';

@NgModule({
    imports: [
      CommonModule,
      AngularFireModule.initializeApp(environment.firebase),
      AngularFirestoreModule,
      AngularFireStorageModule,
      FormsModule,
      ReactiveFormsModule,
      NgbModule
    ],
    declarations: [
      BookingsEditEventFormComponent,
      BookingsEditRoomFormComponent
    ],
    providers: [
    ],
    exports: [
      BookingsEditEventFormComponent,
      BookingsEditRoomFormComponent
    ]
})
export class BookingsFormsModule { }
