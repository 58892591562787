import { MessagesService } from './_services/messages.service';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { RouterModule } from '@angular/router';
import { NgxPermissionsModule } from 'ngx-permissions';
import { MessagesRecentComponent } from './messages-recent/messages-recent.component';

@NgModule({
    imports: [
      CommonModule,
      RouterModule,
      FormsModule,
      ReactiveFormsModule,
      NgxPermissionsModule.forChild()
    ],
    declarations: [
      MessagesRecentComponent
    ],
    providers: [MessagesService],
    exports: [MessagesRecentComponent]
})
export class MessagesRecentModule {
}
