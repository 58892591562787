import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { EventsService } from 'src/app/theme/pages/wealth-spaces/events/_services/events.service';
import { AuthenticationService } from 'src/app/auth/_services';
import { User } from 'src/app/theme/pages/wealth-spaces/users/_models/user.model';
import { BookingsService } from 'src/app/theme/pages/wealth-spaces/bookings/_services/bookings.service';
import { config } from '../../../../../_configs/config';

declare var $: any;

@Component({
    selector: 'ws-bookings-edit-event-form',
    templateUrl: './bookings-edit-event.form.html'
})
export class WSBookingsEditEventFormComponent implements OnInit {

  @Input() bookingData: any;
  @Input() bookingType: any;
  @Input() locations: any;

  @Output() closeModal = new EventEmitter();

  isLoading: boolean = false;
  type: string;

  // FORM
  bookingEditForm: FormGroup;
  isSubmitting: boolean = false;
  removeUpdate: boolean = false;
  showDeleteButtons: boolean = false;

  // LOCATIONS
  locationId: string;
  location: any;
  locationAddress: string;
  locationTypes: any[];

  // USER
  bookingUserInfo: User;

  // DATE
  minDate = undefined;
  bookingDate: any;
  bookingTime: any;
  oldBookingDate: any;
  bookingStatus: any;

  // BOOKING MESSAGE
  showMessage: boolean = false;
  messagePlaceholder: string;

  // ACTIVATE BOOKING
  showActiveMessage: boolean = false;

  // NOTIFICATION
  sendNotification: boolean = false;
  notificationType: string;

  constructor(
    private fb: FormBuilder,
    private auth: AuthenticationService,
    private bookingsService: BookingsService,
    private eventsService: EventsService,
    private datePipe: DatePipe,
    private toastr: ToastrService
  ) {
  }

  ngOnInit() {
    this.isLoading = true;
    console.log(this.bookingData);
    this.buildForm();
    // SET DATE RANGE
    const current = new Date();
    this.minDate = {
      year: current.getFullYear(),
      month: current.getMonth() + 1,
      day: current.getDate()
    };
    // GET BOOKING INFO
    this.bookingUserInfo = this.bookingData.booking.user;
    this.bookingTime = this.bookingData.bookingTime;
    this.bookingStatus = this.bookingData.booking.status;
    this.locationTypes = this.locations.filter((e) => { return e.eventSpace === 'yes' && e.verified === true && e.status === 1; });

    if (Object.prototype.toString.call(this.bookingData.booking.startDate) === "[object Date]") {
      console.log('IS A DATE')
      if (isNaN(this.bookingData.booking.startDate.getTime())) {
        console.log('INVALID DATE')
      } else {
        this.bookingDate = new Date(this.bookingData.booking.startDate.getTime());
        this.bookingData.booking.startDate = new Date(this.bookingData.booking.startDate.getTime());
        this.bookingData.booking.endDate = new Date(this.bookingData.booking.endDate.getTime());
      }
    } else {
      this.bookingDate = this.bookingData.booking.startDate.toDate();
      this.bookingData.booking.startDate = this.bookingData.booking.startDate.toDate();
      this.bookingData.booking.endDate = this.bookingData.booking.endDate.toDate();
    }

    // USER BOOKINGS
    if (this.bookingType !== 'userBookings') {
      this.setForm(current);
      this.notificationType = 'location';
    } else {
      this.bookingEditForm.removeControl('date');
      this.bookingEditForm.removeControl('times');
      this.notificationType = 'user';
    }
    this.isLoading = false;
  }

  buildForm() {
    this.bookingEditForm = this.fb.group({
      date: [''],
      location: ['', [Validators.required]],
      times:  this.fb.group({
        open: ['', [Validators.required]],
        close: ['', [Validators.required]]
      }),
    });
  }

  dateChange() {
    if (this.bookingData.booking.status === 3) {
      this.showActiveMessage = true;
    }
    this.showMessage = true;
    this.messagePlaceholder = 'Reason for change...';
    this.bookingEditForm.addControl('message', new FormControl('', Validators.required));
    const startTime = this.bookingEditForm.get('times.open').value;
    const endTime = this.bookingEditForm.get('times.close').value;
    if (startTime.hour >= endTime.hour) {
      const closeTime = {
        hour: startTime.hour + 1,
        minute: startTime.minute,
        second: 0
      }
      this.bookingEditForm.get('times.close').patchValue(closeTime);
    }
  }

  setForm(current) {

    // SET LOCATION
    const bookingLocation = this.locationTypes.filter((e) => { return e.uid === this.bookingData.booking.locationId; });
    this.bookingEditForm.controls.location.setValue(bookingLocation[0]);
    this.location = bookingLocation[0];

    // SET DATE
    const startDate = this.datePipe.transform(this.bookingData.booking.startDate, 'yyyy-MM-dd');
    const dateYear = Number(this.datePipe.transform(startDate, 'yyyy'));
    const dateMonth = Number(this.datePipe.transform(startDate, 'MM'));
    const dateDay = Number(this.datePipe.transform(startDate, 'dd'));

    this.bookingEditForm.controls.date.setValue({
      year: dateYear,
      month: dateMonth,
      day: dateDay
    });

    // START TIME
    const startTime = this.bookingData.booking.startDate;
    const startHour = startTime.getHours();
    const startMin = startTime.getMinutes();
    const startSec = startTime.getSeconds();

    this.bookingEditForm.controls.times.get('open').setValue({
      hour: startHour,
      minute: startMin,
      second: startSec
    });

    // END TIME
    const endTime = this.bookingData.booking.endDate;
    const endHour = endTime.getHours();
    const endMin = endTime.getMinutes();
    const endSec = endTime.getSeconds();

    this.bookingEditForm.controls.times.get('close').setValue({
      hour: endHour,
      minute: endMin,
      second: endSec
    });

    if (new Date(this.bookingData.booking.startDate) < new Date(current)) {
      this.removeUpdate = true;
      this.bookingEditForm.disable();
    }

    this.bookingEditForm.markAsUntouched();
    this.bookingEditForm.markAsPristine();
  }

  // SET LOCATION
  setLocation() {
    this.location = this.bookingEditForm.get('location').value;
    this.showMessage = true;
    this.messagePlaceholder = 'Reason for change...';
    this.bookingEditForm.addControl('message', new FormControl('', Validators.required));
  }

  getStatus(status) {
    switch (status) {
      case 1:
        return `<span class="badge badge-warning">Pending</span>`;
        break;
      case 2:
        return `<span class="badge badge-success">Confirmed</span>`;
        break;
      case 3:
        return `<span class="badge badge-danger">Cancelled</span>`;
        break;
      default:
    }
  }

  onUpdate() {
    this.isSubmitting = true;
    let times = this.bookingEditForm.get('times').value;

    console.log(times)
    let selectedDate = this.bookingEditForm.get('date').value;

    this.bookingData.booking.startDate = new Date(selectedDate.year, selectedDate.month - 1, selectedDate.day, parseInt(this.pad(times.open.hour)), parseInt(this.pad(times.open.minute)), parseInt(this.pad(times.open.second)));
    this.bookingData.booking.endDate = new Date(selectedDate.year, selectedDate.month - 1, selectedDate.day, parseInt(this.pad(times.close.hour)), parseInt(this.pad(times.close.minute)), parseInt(this.pad(times.close.second)));
    this.bookingData.booking.locationId = this.location.uid;
    this.bookingData.booking.locationName = this.location.name;
    this.bookingData.booking.address = this.location.address;
    this.bookingData.booking.locationRef = `entities/${config.entityId}/locations/${this.location.uid}`;

    if (this.showActiveMessage) this.bookingData.booking.status = 2;
    let pendingData = {
      startDate: this.bookingData.booking.startDate,
      locationRef: this.bookingData.booking.locationRef,
      oldDate: this.bookingDate,
      oldStatus: this.bookingStatus,
      status: this.bookingData.booking.status,
      createdBy: this.bookingData.booking.createdBy,
      user: this.bookingData.booking.user
    };

    this.bookingsService.updateBooking(this.bookingData.booking, this.bookingData.booking.uid).then(() => {
      this.isSubmitting = false;
      this.bookingEditForm.markAsPristine();
      this.toastr.success('Booking updated');
      this.closeModal.emit(true);
      // this.bookingsService.sendPendingNotify(pendingData, this.bookingEditForm.get('message').value, this.notificationType).then(() => {
      //   this.isSubmitting = false;
      //   this.bookingEditForm.markAsPristine();
      //   this.toastr.success('Booking updated');
      //   this.closeModal.emit(true);
      // }).catch(error => {
      //   this.toastr.error('Error updating booking : ', error);
      // });
    })
  }

  private pad(i: number): string {
      return i < 10 ? `0${i}` : `${i}`;
  }

  onDelete(type, remove?) {
    if (type === 'warning') {
      this.showDeleteButtons = true;
      if (this.bookingTime === 'future' && !remove) {
        this.showMessage = true;
        this.messagePlaceholder = 'Reason for cancellation...';
        this.bookingEditForm.addControl('message', new FormControl('', Validators.required));
      }
    }
    if (type === 'delete') {
      let toastrMsg = '';
      if (this.bookingTime === 'future' && !remove) {
        this.bookingData.booking.status = 3;
        toastrMsg = 'Booking cancelled';
        this.sendNotification = true;
      }
      else {
        this.bookingData.booking.active = false;
        toastrMsg = 'Booking removed';
        this.sendNotification = false;
      }

      this.bookingsService.updateBooking(this.bookingData.booking, this.bookingData.booking.uid).then(() => {
        this.toastr.success(toastrMsg);
        this.showDeleteButtons = false;
        this.showMessage = false;
        this.messagePlaceholder = '';
        this.closeModal.emit(true);
        // if (this.sendNotification) {
        //   this.bookingsService.sendPendingNotify(this.bookingData.booking, this.bookingEditForm.get('message').value, this.notificationType).then(() => {
        //     this.toastr.success(toastrMsg);
        //     this.showDeleteButtons = false;
        //     this.showMessage = false;
        //     this.messagePlaceholder = '';
        //     this.closeModal.emit(true);
        //   });
        // } else {
        //   this.toastr.success(toastrMsg);
        //   this.showDeleteButtons = false;
        //   this.showMessage = false;
        //   this.messagePlaceholder = '';
        //   this.closeModal.emit(true);
        // }
      }).catch(error => {
        this.toastr.error('Error deleting booking : ', error);
      })
    }
    if (type === 'cancel') {
      this.showDeleteButtons = false;
      this.showMessage = false;
      this.messagePlaceholder = '';
      if (this.bookingType !== 'userBookings') {
        const current = new Date();
        this.setForm(current);
      }
      if (this.bookingEditForm.get('message')) {
        this.bookingEditForm.removeControl('message');
      }
    }
  }

  onCancelRsvp() {
    const updateData = {
      active: false,
      attending: false
    }
    this.eventsService.updateEventUser(this.bookingData.booking.typeId, this.auth.userId, updateData).then(() => {
      this.toastr.success('Cancelled event RSVP');
      this.closeModal.emit(true);
    }).catch(error => {
      this.toastr.error('Error cancelling RSVP : ', error);
    });
  }

}
