import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl, ValidatorFn, FormArray, NgForm, FormControl } from '@angular/forms';
import { productRenewal } from 'src/app/_shared/_models/product-renewal';
import { User } from '../../../../../theme/pages/wealth-spaces/users/_models/user.model';
import { TeamsService } from 'src/app/theme/pages/wealth-spaces/teams/_services/teams.service';
import { SubscriptionsService } from 'src/app/theme/pages/wealth-spaces/subscriptions/_services/subscriptions.service';
import { TransactionsService } from 'src/app/theme/pages/wealth-spaces/transactions/_services/transactions.service';
import { UsersService } from '../../../../../theme/pages/wealth-spaces/users/_services/users.service';
import { Subject, Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import * as moment from 'moment';

@Component({
  selector: 'ws-product-payment-steps',
  templateUrl: './ws-product-payment-steps.component.html',
  styleUrls: ['../ws-products.scss']
})
export class WSProductPaymentStepsComponent implements OnInit {

  @Input() extraData: any;

  @Output() closeModal = new EventEmitter();

  updateForm: FormGroup;
  user: User;
  team: any;
  teamUsers: any;
  payerInfo: any;
  isUpdating: boolean = false;
  showLegal: boolean = false;

  productStepsForm: FormGroup;
  productsSubscription: Subscription;
  isLoading: boolean = false;
  product: any;
  isSubmitting: boolean = false;


  // CONFIRM
  confirm: boolean = false;
  renewDays = productRenewal;
  renewalForm: FormGroup;

  isRedirecting: boolean = false;

  // CARDS
  payer: any;
  userCardsSubscription: Subscription;
  userCards: any[];
  changeDefault: boolean = false;
  changedCardId: string;
  amount: number;
  proRataAmount: number;
  deletingCard: boolean = false;
  deletedCardSubscription: Subscription;

  oldSubscription: any;

  constructor(
    private fb: FormBuilder,
    private usersService: UsersService,
    private toastr: ToastrService,
    private subscriptionsService: SubscriptionsService,
    private teamsService: TeamsService,
    private transactionsService: TransactionsService,
  ) {
  }

  ngOnInit() {
    this.user = this.extraData.data.user;
    this.team = this.extraData.data.team;
    this.product = this.extraData.data.product;
    this.oldSubscription = this.extraData.data.oldSubscription;

    this.buildForms();

    if (this.user) {
      this.amount = this.product.regularPrice;
      this.payer = this.user;
      if (this.user.billingInfo) {
        this.updateForm.patchValue(this.user);
      }
      if (this.oldSubscription) {
        this.renewalForm.get('renewalDay').patchValue(this.oldSubscription.frequency);
        this.renewalForm.markAsDirty();
        this.getProRata();
      }
    }

    if (this.team) {
      this.payerInfo = this.extraData.data.payerInfo;
      this.payer = this.payerInfo;
      this.amount = this.product.amount;
      this.teamUsers = this.extraData.data.teamUsers;
      if (this.team.billingInfo) {
        this.showLegal = true;
        this.updateForm.patchValue(this.team);
      }
      if (this.oldSubscription) {
        this.renewalForm.get('renewalDay').patchValue(this.oldSubscription.frequency);
        this.renewalForm.markAsDirty();
        this.getProRata();
      }
    }

    // GET USER CARDS
    this.userCardsSubscription = this.transactionsService.getUserCards(this.payer.uid).subscribe(cardsData => {
      console.log(cardsData);
      this.userCards = cardsData.filter((card: any) => { if (card.vaultId) return card; });
      if (this.userCards.length > 0) {
        if (this.userCards.length === 1) {
          this.transactionsService.setDefaultCard(this.userCards[0].id).then(() => {
            this.setDefaultCard();
          });
        } else {
          this.setDefaultCard();
        }
      }
    })

  }

  buildForms() {

    if (this.user) {
      this.updateForm = this.fb.group({
        billingInfo: this.fb.group({
          billingName: ['', Validators.required],
          billingEmail: ['', [Validators.required, Validators.email]],
          billingAddress: this.fb.group({
            streetAddress: ['', Validators.required],
            city: ['', Validators.required],
            postalCode: ['', Validators.required],
            country: ['South Africa'],
            region: ['', Validators.required],
          }),
          taxNumber: ['']
        })
      });
    }

    if (this.team) {
      this.updateForm = this.fb.group({
        billingInfo: this.fb.group({
          billingName: ['', Validators.required],
          billingEmail: ['', [Validators.required, Validators.email]],
          billingAddress: this.fb.group({
            streetAddress: ['', Validators.required],
            city: ['', Validators.required],
            postalCode: ['', Validators.required],
            country: ['South Africa'],
            region: ['', Validators.required],
          }),
          taxNumber: [''],
          legalStatus: ['', [Validators.required]]
        })
      });
    }

    this.renewalForm = this.fb.group({
      renewalDay: ['', Validators.required],
      card: [''],
      defaultCard: [''],
    });

  }

  getProRata() {
    const day = Number(this.renewalForm.get('renewalDay').value);
    const lastDay = Number(moment().endOf('month').format('D'));
    const today = Number(moment().format('D'));
    let dayDifference;

    if (day !== -1) {
      dayDifference = (day >= today) ? day - today : dayDifference;
      dayDifference = (day < today) ? lastDay + day - today : dayDifference;
    } else {
      dayDifference = lastDay - today;
    }

    const daysDiscount = +((this.amount / lastDay).toFixed(2));
    this.proRataAmount = (dayDifference === 0) ? this.amount : +((daysDiscount * dayDifference).toFixed(2));
  };

  // CARDS
  setDefaultCard() {
    const defaultCard = this.userCards.filter((card) => {
      return card.default == true;
    });
    if (defaultCard[0]) {
      this.renewalForm.get('card').patchValue(defaultCard[0].id);
    }
  }

  onCardChange() {
    this.changedCardId = this.renewalForm.get('card').value;
    if (this.changedCardId) {
      const noChange = this.userCards.some(card => card.id === this.changedCardId && card.default === true);
      if (!noChange) {
        this.changeDefault = true;
        this.renewalForm.get('defaultCard').reset();
      } else {
        this.changeDefault = false;
      }
    }
  }

  setCardAsDefault() {
    const setDefault = this.renewalForm.get('defaultCard').value;
    if (setDefault) {
      this.transactionsService.setDefaultCard(this.changedCardId, this.payer.uid).then(() => {
        this.toastr.success(`Default card changed`);
      });
    }
  }

  deleteCard(cardId) {
    this.deletingCard = true;
    this.transactionsService.deleteCard(cardId).then(data => {
      this.deletedCardSubscription = this.transactionsService.getDeletedCard(data).subscribe(card => {
        if (!card) {
          this.deletingCard = false;
        }
      });
    });
  }

  onUpdate(editForm) {
    this.isUpdating = true;
    let updateInfo;
    if (this.updateForm.valueChanges && this.updateForm.dirty) {
      if (this.user) {
        updateInfo = this.user;
        updateInfo.billingInfo = this.updateForm.get('billingInfo').value;
        this.usersService
          .updateUser(updateInfo)
          .then(() => {
            this.isUpdating = false;
            this.toastr.success('Billing info updated');
            this.updateHistory(editForm);
            this.updateForm.markAsPristine();
            this.updateForm.markAsUntouched();
            this.confirm = true;
          })
          .catch(err => {
            this.isUpdating = false;
            this.toastr.error('Error updating billing info: ', err);
          });
      }
      if (this.team) {
        updateInfo = {
          billingInfo: this.updateForm.get('billingInfo').value
        };
        this.teamsService
        .updateTeam(updateInfo, this.team.uid)
        .then(() => {
          this.isUpdating = false;
          this.toastr.success('Billing info updated');
          this.updateForm.markAsPristine();
          this.updateForm.markAsUntouched();
          this.confirm = true;
        })
        .catch(err => {
          this.isUpdating = false;
          this.toastr.error('Error updating billing info: ', err);
        });
      }
    } else {
      this.isUpdating = false;
    }
  }

  onConfirm() {
    this.confirm = true;
  }

  makePayment() {
    let paymentUrl = 'https://secret-island-96823.herokuapp.com/';
    let paymentUrlParams;
    let cardId;
    let card;
    if (this.renewalForm.get('card').value && this.userCards.length > 0) {
      cardId = `&cardId=${this.renewalForm.get('card').value}`;
      card = `&card=No`;
    } else {
      cardId = '';
      card = `&card=Yes`;
    }
    let dataToSend = {
      user: undefined,
      team: undefined,
      teamUsers: undefined,
      payerInfo: undefined,
      transactionId: undefined,
      type: undefined,
      typeId: undefined,
      product: this.product,
      renewalDay: this.renewalForm.get('renewalDay').value
    }
    if (this.user) {
      dataToSend.user = this.user;
      dataToSend.type = 'userSubscription';
      dataToSend.typeId = this.user.uid;
    }
    if (this.team) {
      dataToSend.team = this.team;
      dataToSend.payerInfo = this.payerInfo;
      dataToSend.type = 'teamSubscription';
      dataToSend.typeId = this.team.uid;
      dataToSend.teamUsers = this.teamUsers;
    }
    this.subscriptionsService.addUserPendingSubscription(dataToSend).then(() => {
      this.transactionsService.addTransaction(dataToSend, this.proRataAmount).then(transactionId => {
        if (transactionId) {
          this.isRedirecting = true;
          if (this.user) {
            paymentUrlParams = `${paymentUrl}?amount=${this.proRataAmount}&returnUrl=${environment.websiteUrl}payments/confirmation?transactionId=${transactionId}&userId=${this.user.uid}&transactionId=${transactionId}${card}${cardId}`;
            window.location.href = paymentUrlParams;
          }
          if (this.team) {
            paymentUrlParams = `${paymentUrl}?amount=${this.proRataAmount}&returnUrl=${environment.websiteUrl}payments/confirmation?transactionId=${transactionId}&userId=${this.payerInfo.uid}&transactionId=${transactionId}${card}${cardId}`;
            window.location.href = paymentUrlParams;
          }
        }
      });
    });
  }

  updateHistory(editForm) {
    // UPDATE USER HISTORY
    const handleFormData = (form: FormGroup | FormArray) => new Promise((resolve, reject) => {
      let changedArray = [];
      if (form.dirty) {
        for (let i in form.controls) {
          if (form.controls[i] instanceof FormControl && form.controls[i].dirty) {
            changedArray.push({ field: i, value: form.controls[i].value });
          }
        }
        resolve(changedArray);
      } else {
        reject('No changes detected');
      }
    });
    return handleFormData(editForm).then((changedFieldsArray) => {

      return this.usersService.addHistoryLogToUser(changedFieldsArray, this.user.uid);
    }).catch((err) => {
      // this.toastr.error(err);
      return err;
    });
  }

  ngOnDestroy() {
    this.userCardsSubscription.unsubscribe();
    this.closeModal.emit(true);
    if (this.deletedCardSubscription) {
      this.deletedCardSubscription.unsubscribe();
    }
  }

}
