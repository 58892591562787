import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BookingsFormsModule } from './bookings-forms/bookings-forms.module';

@NgModule({
    imports: [
      CommonModule,
      BookingsFormsModule
    ],
    declarations: [
    ],
    providers: [
    ],
    exports: [
      BookingsFormsModule
    ]
})
export class AppFormsModule { }
