import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Subject, Subscription, Observable } from 'rxjs';
import { PerfectScrollbarConfigInterface, PerfectScrollbarComponent } from 'ngx-perfect-scrollbar';

@Component({
  selector: 'app-transactions-list',
  templateUrl: './transactions-list.html',
  styleUrls: ['../scrollable-lists.scss']
})
export class TransactionsListComponent implements OnInit, OnDestroy {

    @Input() transactions$: Observable<Array<{}>>;
    @Output() sendTransaction = new EventEmitter();

    isLoading: boolean = false;

    transactionsSubscription: Subscription;
    transactions: any[];

    public config: PerfectScrollbarConfigInterface = {};
    @ViewChild(PerfectScrollbarComponent, { static: false }) componentRef?: PerfectScrollbarComponent;

    constructor(
    ) { }

    ngOnInit() {
      this.isLoading = true;
      this.transactionsSubscription = this.transactions$.subscribe(transactionsData => {
          this.transactions = transactionsData;
          this.isLoading = false;
      });
    }

    getType(type, transaction) {
        switch (type) {
          case 'userSubscription':
            return `Personal Subscription `;
            break;
          case 'teamSubscription':
            return `${transaction.paidByName} - Team Subscription `;
            break;
          case 'meetingRoom':
            return `Meeting Room Booking `;
            break;
          default:
        }
    }

    getId(id) {
      console.log(id)
    }

    getStatus(type) {
        switch (type) {
            case 1:
                return `<span class="badge badge-warning">Pending</span>`;
                break;
            case 2:
                return `<span class="badge badge-success">Paid</span>`;
                break;
            case 3:
                return `<span class="badge badge-danger">Unsuccessful</span>`;
                break;
            case 4:
                return `<span class="badge badge-light">Cancelled</span>`;
                break;
          default:
        }
    }

    ngOnDestroy(): void {
        this.transactionsSubscription.unsubscribe();
    }

}
