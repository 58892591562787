import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgxPermissionsModule, NgxPermissionsService } from 'ngx-permissions';
import { AuthModule } from '../auth/auth.module';
import { AuthenticationService } from '../auth/_services';
import { UsersService } from '../theme/pages/wealth-spaces/users/_services/users.service';
import { EntitiesService } from './_services/entities.service';
import { FilesModule } from './_components/files/files.module';
import { FilesService } from './_components/files/files.service';
import { GenerateThumbnailService } from './_services/generate-thumbnail.service';
import { DataTablesModule } from 'angular-datatables';
import { AuditLogService } from './_services/audit-log.service';
import { ArchwizardModule } from 'angular-archwizard';
import { AgmCoreModule } from '@agm/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TeamsService } from '../theme/pages/wealth-spaces/teams/_services/teams.service';
import { LocationsService } from '../theme/pages/wealth-spaces/locations/_services/locations.service';
import { ProductsService } from '../theme/pages/wealth-spaces/products/_services/products.service';
import { MapsModule } from './_components/maps/maps.module';
import { SortablejsModule } from 'ngx-sortablejs';
import { UserSearchPipe } from './_pipes/users-search.pipe';
import { MeetingRoomsService } from '../theme/pages/wealth-spaces/meeting-rooms/_services/meeting-rooms.service';
import { EventsService } from '../theme/pages/wealth-spaces/events/_services/events.service';
import { BookingsService } from '../theme/pages/wealth-spaces/bookings/_services/bookings.service';
import { SubscriptionsService } from '../theme/pages/wealth-spaces/subscriptions/_services/subscriptions.service';
import { WealthSpacesCompModule } from './_components/wealthspaces/wealthspaces.module';
import { ModalsModule } from './_components/modals/modals.module';
import { CalendarsModule } from './_components/calendars/calendars.module';
import { AppFormsModule } from './_components/forms/app-forms.module';
import { NotificationsService } from './_services/notifications.service';
import { MessagesRecentModule } from './_components/messages-recent/messages-recent.module';
import { TransactionsService } from '../theme/pages/wealth-spaces/transactions/_services/transactions.service';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { NgxTinymceModule } from 'ngx-tinymce';
import { PaymentsService } from '../theme/pages/wealth-spaces/payments/_services/payments.service';
import { ScrollableListsModule } from './_components/scrollable-lists/scrollable-lists.module';
import { CouponsService } from '../theme/pages/wealth-spaces/coupons/_services/coupons.service';

@NgModule({
  declarations: [
    UserSearchPipe
  ],
  imports: [
    CommonModule,
    AuthModule,
    NgxPermissionsModule,
    RouterModule,
    FilesModule,
    DataTablesModule,
    ArchwizardModule,
    SortablejsModule.forRoot({ animation: 150 }),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyDtzpkn_YCwOiDNLhP54Ye8tz1p352lW4k',
      libraries: ['places']
    }),
    NgbModule,
    MapsModule,
    WealthSpacesCompModule,
    ModalsModule,
    CalendarsModule,
    AppFormsModule,
    MessagesRecentModule,
    NgMultiSelectDropDownModule.forRoot(),
    NgxTinymceModule.forRoot({
      baseURL: '//cdnjs.cloudflare.com/ajax/libs/tinymce/5.3.2/'
    }),
    ScrollableListsModule,
  ],
  exports: [
    AuthModule,
    NgxPermissionsModule,
    RouterModule,
    FilesModule,
    DataTablesModule,
    ArchwizardModule,
    SortablejsModule,
    AgmCoreModule,
    NgbModule,
    MapsModule,
    UserSearchPipe,
    WealthSpacesCompModule,
    ModalsModule,
    CalendarsModule,
    AppFormsModule,
    MessagesRecentModule,
    NgMultiSelectDropDownModule,
    NgxTinymceModule,
    ScrollableListsModule,
  ],
  providers: [DatePipe]
})
export class AppCoreModule {
  static forRoot() {
    return {
      ngModule: AppCoreModule,
      providers: [
        AuthenticationService,
        AuditLogService,
        NgxPermissionsService,
        EntitiesService,
        UsersService,
        FilesService,
        GenerateThumbnailService,
        TeamsService,
        LocationsService,
        ProductsService,
        MeetingRoomsService,
        EventsService,
        BookingsService,
        SubscriptionsService,
        NotificationsService,
        TransactionsService,
        PaymentsService,
        CouponsService
      ]
    };
  }
}
