import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { Router } from "@angular/router";
import { config } from '../../../../../_shared/_configs/config';
import { combineLatest, Subscription } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { FilesService } from 'src/app/_shared/_components/files/files.service';
import { User } from '../../users/_models/user.model';
import { AuthenticationService } from 'src/app/auth/_services';
import { UsersService } from '../../users/_services/users.service';

declare var toastr: any;

@Injectable()
export class ProductsService {

  entityId: string;

  authSubscription: Subscription;
  loggedInUser: any;

  constructor(
    public afs: AngularFirestore,
    public router: Router,
    private filesService: FilesService,
    private auth: AuthenticationService,
    private usersService: UsersService
  ) {
    this.entityId = config.entityId;
    this.authSubscription = this.auth.user.subscribe(userDetails => {
      if (userDetails) {
        this.loggedInUser = userDetails;
      }
    });
  }

  getProducts() {
    const productsCollection = this.afs.collection(`entities/${this.entityId}/products`, ref => ref.where('publish', '==', true).orderBy('created', 'desc'));
    return productsCollection.valueChanges({ idField: 'id' });
  }

  getPublicProducts() {
    const productsCollection = this.afs.collection(`public/${this.entityId}/products`, ref => ref.where('publish', '==', true).orderBy('created', 'asc'));
    return productsCollection.valueChanges({ idField: 'id' });
  }

  getProductDetails(productId) {
    const getProductDetails = this.afs.doc(`/entities/${config.entityId}/products/${productId}`);
    return getProductDetails.valueChanges();
  }

  addProduct(product) {
    const productsCollection = this.afs.collection(`entities/${this.entityId}/products`);

    let productData = {
      active: true,
      publish: product.publish,
      title: product.title,
      description: product.description,
      regularPrice: product.regularPrice,
      referencePrefix: product.title.replace(/\s/g, "-").toUpperCase(),
      created: new Date(),
      createdBy: this.loggedInUser.uid,
      uploadedPhoto: '',
      uploadedPhotoThumbnail: '',
      access: product.access,
      source: config.source
    }

    return productsCollection.add(productData).then(ref => {
      const productsDoc = this.afs.doc(`entities/${this.entityId}/products/${ref.id}`);

      return productsDoc.set({uid: ref.id}, {merge: true}).then(() => {
        if (product.productImage) {

          // SET REF PATH TO SAVE
          let saveRef = {
            dbPath: `entities/${config.entityId}/products/${ref.id}`,
            filePath: `entities/${config.entityId}/products/${ref.id}/${ref.id}`,
            uploadType: 'cropper',
            thumbSize: 150
          };

          const uploadFiles = this.filesService.handleDrop(product.productImage, saveRef);
          return Promise.resolve(uploadFiles);

        }
      });

    });
  }

  updateProduct(productId, product) {
    const prosuctsDoc = this.afs.doc(`entities/${this.entityId}/products/${productId}`);
    return prosuctsDoc.set(product, { merge: true });
  }

}
