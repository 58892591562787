import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { Subscription } from "rxjs";
import { Router } from "@angular/router";
import { AuthenticationService } from "../../../../auth/_services";

@Injectable()
export class MessagesService {
  entityId: string;

  authSubscription: Subscription;
  loggedInUser: any;

  constructor(
    public afs: AngularFirestore,
    public router: Router,
    private auth: AuthenticationService,
  ) {}

  getConversationsForUser(userId) {
    const userConversations = this.afs.collection(`users/${userId}/entities/wealthspaces-sa/conversations`, ref => ref.orderBy('lastMessageDate', 'desc'));
    return userConversations.valueChanges({idField: 'id'});
  }

  getMessagesForConversation(userId, convoId) {
    return this.afs.collection(
      `users/${userId}/entities/wealthspaces-sa/conversations/${convoId}/messages`,
      ref => ref.orderBy('created', 'asc')
    ).valueChanges({idField: 'id'});
  }

  addMessageToConversation(userId, convoId, message) {
    return this.afs.collection(
      `users/${userId}/entities/wealthspaces-sa/conversations/${convoId}/messages`
    ).add(message);
  }

  createConversation(currentUserId, guestUserId) {
    let userRef = this.afs.doc(`users/${guestUserId}`).ref;

    return userRef.get().then((guestUserDetailsData) => {
      const guestUserDetails = guestUserDetailsData.data();

      let imageUrl = "/assets/images/icon/WS-ICON.png";
      if (guestUserDetails.hasOwnProperty("photoURL")) {
        imageUrl = imageUrl;
      }
      const conversationDetails = {
        created: new Date(),
        createdById: currentUserId,
        name: guestUserDetails.firstname + " " + guestUserDetails.surname,
        image: imageUrl,
        lastMessageDate : new Date()
      };
      let userConvoRef = `users/${currentUserId}/entities/wealthspaces-sa/conversations/${guestUserId}`;
      return this.afs
        .doc(userConvoRef)
        .set(conversationDetails, { merge: true })
        .then((ref) => {
          return this.afs.collection(userConvoRef + "/messages").add({
            created: new Date(),
            createdById: "system",
            text: `${this.auth.userDetails.firstname} started a conversation`,
            active: true,
          });
        });
    });
  }
}
