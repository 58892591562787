import { Component } from '@angular/core';

@Component({
  selector: 'app-register-page',
  templateUrl: './register-page.component.html',
  styleUrls: ['./register-page.component.scss']
})
export class RegisterPageComponent {

  showHide: boolean = false;
  passReset: boolean = false;

  constructor(
    ) {

  }

}
