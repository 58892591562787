import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl, ValidatorFn, FormArray, NgForm, FormControl } from '@angular/forms';
import { StorageService } from 'src/app/_shared/_services/storage.service';
import { ProductsService } from 'src/app/theme/pages/wealth-spaces/products/_services/products.service';
import { SubscriptionsService } from 'src/app/theme/pages/wealth-spaces/subscriptions/_services/subscriptions.service';
import { Subject, Subscription } from 'rxjs';

@Component({
  selector: 'ws-product-select',
  templateUrl: './ws-product-select.component.html',
  styleUrls: ['../ws-products.scss']
})
export class WSProductSelectComponent implements OnInit, OnDestroy {

  @Input() extraData: any;

  @Output() closeModal = new EventEmitter();

  productForm: FormGroup;
  productsSubscription: Subscription;
  isLoading: boolean = false;
  products: any[];
  isSubmitting: boolean = false;
  activeProduct: any[];


  modalSubscription: Subscription;

  constructor(
    private fb: FormBuilder,
    private productsService: ProductsService,
    private storageService: StorageService,
    private subscriptionsService: SubscriptionsService
  ) {
  }

  ngOnInit() {
    this.buildForm();
    this.productsSubscription = this.productsService.getPublicProducts().subscribe(productsData => {
      this.products = productsData;
      if (this.extraData.data.subscription) {
          this.activeProduct = this.products.filter((e) => { return e.refNo === this.extraData.data.subscription.productRef; });
          this.productForm.get('product').patchValue(this.activeProduct[0]);
      }
    });
    this.modalSubscription = this.storageService.currentModal.subscribe(modal => {
      if (Object.entries(modal).length === 0) {
        if (this.activeProduct) {
          this.productForm.get('product').patchValue(this.activeProduct[0]);
        } else {
          this.productForm.reset();
        }
      }
    });
  }

  buildForm() {
    this.productForm = this.fb.group({
      product: ['', [Validators.required]]
    });
  }

  productSelect() {
    const values = this.productForm.value;
    values.user = this.extraData.data.user;
    if (this.extraData.data.subscription) {
      values.oldSubscription = this.extraData.data.subscription;
    } else {
      values.oldSubscription = undefined;
    }
    if (this.extraData.submit) {
      const modalData = {
        modalId: 'productModal',
        title: `${values.product.title}`,
        type: 'productSteps',
        data: values
      }
      this.storageService.openModal(modalData);
    } else {
    }
  }

  onSubmit() {
    const values = this.productForm.value;
    values.user = this.extraData.data.user;
    if (this.extraData.submit) {
    } else {
      this.storageService.selectedProduct(values);
      this.closeModal.emit(true);
    }
  }

  ngOnDestroy() {
    this.productsSubscription.unsubscribe();
    this.modalSubscription.unsubscribe();
  }

}
