import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthComponent } from './auth.component';
import { LoginPageComponent } from './pages/login-page/login-page.component';
import { AuthGuard } from './_guards/auth.guard';
import { VerificationPageComponent } from './pages/verification-page/verification-page.component';
import { RegisterPageComponent } from './pages/register-page/register-page.component';

const routes: Routes = [
    { path: '', component: AuthComponent, children: [
        { path: 'login', component: LoginPageComponent },
        { path: 'user', redirectTo: '/profile', canActivate: [AuthGuard] },
        { path: 'verification/:uid', component: VerificationPageComponent },
        { path: 'register/:uid', component: RegisterPageComponent },
        // { path: 'verification', component: VerificationComponent },
        // { path: 'redirect/:url', component: UserRedirectComponent },
        { path: '', redirectTo: '/dashboard', pathMatch: 'full' }
    ] },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class AuthRoutingModule {
}
