import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Location, DatePipe } from '@angular/common';
import { FormGroup, FormBuilder, Validators, AbstractControl, ValidatorFn, FormArray, NgForm, FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { config } from '../../../../../_configs/config';
import { BookingsService } from 'src/app/theme/pages/wealth-spaces/bookings/_services/bookings.service';

declare var $: any;

@Component({
    selector: 'app-bookings-edit-event-form',
    templateUrl: './bookings-edit-event.form.html',
    styleUrls: ['../../../app-forms.scss']
})
export class BookingsEditEventFormComponent implements OnInit {

  @Input() bookingData: any;
  @Input() bookingType: any;
  @Input() locations: any;

  @Output() closeModal = new EventEmitter();

  isLoading: boolean = false;
  type: string;

  // FORM
  bookingEditForm: FormGroup;
  isSubmitting: boolean = false;
  removeUpdate: boolean = false;
  showDeleteButtons: boolean = false;

  // LOCATIONS
  locationId: string;
  location: any;
  locationAddress: string;
  locationTypes: any[];

  // DATE
  minDate = undefined;

  constructor(
    private fb: FormBuilder,
    private bookingsService: BookingsService,
    private datePipe: DatePipe,
    private toastr: ToastrService
  ) {
  }

  ngOnInit() {
    this.isLoading = true;
    this.buildForm();
    // SET DATE RANGE
    const current = new Date();
    this.minDate = {
      year: current.getFullYear(),
      month: current.getMonth() + 1,
      day: current.getDate()
    };
    this.locationTypes = this.locations.filter((e) => { return e.eventSpace === 'yes'; });
    if (this.bookingType === 'generalBookings') {
      this.setLocationValidators();
    } else {
      this.disableLocationField();
    }
    this.setForm(current);
    this.isLoading = false;
  }

  buildForm() {
    this.bookingEditForm = this.fb.group({
      date: [''],
      location: [''],
      times:  this.fb.group({
        open: ['', [Validators.required]],
        close: ['', [Validators.required]]
      }),
    });
  }

  setLocationValidators() {
    this.bookingEditForm.get('location').setValidators(Validators.required);
    this.bookingEditForm.get('location').updateValueAndValidity();
  }

  disableLocationField() {
    this.bookingEditForm.get('location').disable();
  }

  setForm(current) {

    // SET LOCATION
    const bookingLocation = this.locationTypes.filter((e) => { return e.uid === this.bookingData.booking.locationId; });
    this.bookingEditForm.controls.location.setValue(bookingLocation[0]);
    this.location = bookingLocation[0];

    // SET DATE
    const startDate = this.datePipe.transform(this.bookingData.booking.startDate.toDate(), 'yyyy-MM-dd');
    const dateYear = Number(this.datePipe.transform(startDate, 'yyyy'));
    const dateMonth = Number(this.datePipe.transform(startDate, 'MM'));
    const dateDay = Number(this.datePipe.transform(startDate, 'dd'));

    this.bookingEditForm.controls.date.setValue({
      year: dateYear,
      month: dateMonth,
      day: dateDay
    });

    // START TIME
    const startTime = this.bookingData.booking.startDate.toDate();
    const startHour = startTime.getHours();
    const startMin = startTime.getMinutes();
    const startSec = startTime.getSeconds();

    this.bookingEditForm.controls.times.get('open').setValue({
      hour: startHour,
      minute: startMin,
      second: startSec
    });

    // END TIME
    const endTime = this.bookingData.booking.endDate.toDate();
    const endHour = endTime.getHours();
    const endMin = endTime.getMinutes();
    const endSec = endTime.getSeconds();

    this.bookingEditForm.controls.times.get('close').setValue({
      hour: endHour,
      minute: endMin,
      second: endSec
    });

    if (new Date(this.bookingData.booking.startDate.toDate()) < new Date(current)) {
      this.removeUpdate = true;
      console.log(this.removeUpdate);
      this.bookingEditForm.disable();
    }
  }

  // SET LOCATION
  setLocation() {
    this.location = this.bookingEditForm.get('location').value;
  }

  onUpdate() {
    this.isSubmitting = true;
    let times = this.bookingEditForm.get('times').value;
    let selectedDate = this.bookingEditForm.get('date').value;

    this.bookingData.booking.startDate = new Date(selectedDate.year, selectedDate.month - 1, selectedDate.day, parseInt(this.pad(times.open.hour)), parseInt(this.pad(times.open.minute)), parseInt(this.pad(times.open.second)));
    this.bookingData.booking.endDate = new Date(selectedDate.year, selectedDate.month - 1, selectedDate.day, parseInt(this.pad(times.close.hour)), parseInt(this.pad(times.close.minute)), parseInt(this.pad(times.close.second)));
    this.bookingData.booking.locationId = this.location.uid;
    this.bookingData.booking.locationName = this.location.name;
    this.bookingData.booking.address = this.location.address;
    this.bookingData.booking.locationRef = `entities/${config.entityId}/locations/${this.location.uid}`;

    this.bookingsService.updateBooking(this.bookingData.booking, this.bookingData.booking.uid).then(() => {
      this.isSubmitting = false;
      this.bookingEditForm.markAsPristine();
      this.toastr.success('Booking updated');
      this.closeModal.emit(true);
    }).catch(error => {
      this.toastr.error('Error updating booking : ', error);
    })
  }

  private pad(i: number): string {
      return i < 10 ? `0${i}` : `${i}`;
  }

  onDelete(type) {
    if (type === 'warning') {
      this.showDeleteButtons = true;
    }
    if (type === 'delete') {
      this.bookingData.booking.active = false;
      this.bookingsService.updateBooking(this.bookingData.booking, this.bookingData.booking.uid).then(() => {
        this.toastr.success('Booking deleted');
        this.showDeleteButtons = false;
        this.closeModal.emit(true);
      }).catch(error => {
        this.toastr.error('Error deleting booking : ', error);
      })
    }
    if (type === 'cancel') {
      this.showDeleteButtons = false;
    }
  }

}
