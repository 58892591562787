import { config } from "../../../../_shared/_configs/config";

export class Conversation {
  id?: string;
  name?: string;
  created?: Date;
  lastMessageDate?: Date;
  image?: string;
  active?: boolean;

  constructor(
  ) { 
    this.id = 'wealthspaces';
    this.name = 'Wealth Spaces';
    this.image = config.wealthSpacesIcon;
    this.lastMessageDate = new Date;
    this.active = true;
  }
}
