import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { Router } from "@angular/router";
import { config } from '../../../../../_shared/_configs/config';
import { Subscription } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/auth/_services';
import { colors } from '../../../../../_shared/_components/calendars/_utils/colors';
import { NotificationsService } from 'src/app/_shared/_services/notifications.service';
import * as moment from 'moment';

@Injectable()
export class BookingsService {

  entityId: string;

  authSubscription: Subscription;
  loggedInUser: any;

  constructor(
    public afs: AngularFirestore,
    public router: Router,
    private auth: AuthenticationService,
    private notificationsService: NotificationsService
  ) {
    this.entityId = config.entityId;
    this.authSubscription = this.auth.user.subscribe(userDetails => {
      if (userDetails) {
        this.loggedInUser = userDetails;
      }
    });
  }

  getBookings() {
    const bookingsCollection = this.afs.collection(`entities/${this.entityId}/bookings`, ref => ref.where('active', '==', true));
    return bookingsCollection.snapshotChanges().pipe(map(changes => {
      return changes.map(a => {
        const booking = a.payload.doc.data() as any;
        let color;
        let bookingTime = 'future';
        if (booking.type === 'event') {
            color = colors.blue
        }
        if (booking.type === 'meetingRoom') {
            color = colors.yellow
        }
        if (booking.type === 'location') {
            color = colors.red
        }
        if (booking.status === 3) {
            color = colors.grey
        }
        if (new Date(booking.endDate.toDate()) < new Date()) {
          bookingTime = 'past';
        }
        this.afs.doc(`users/${booking.createdBy}`).ref.get().then(bookingUser => {
          if (bookingUser.exists) {
            booking.user = bookingUser.data();
          } else {
            booking.user = '';
          }
        });
        return {
          title: booking.name,
          start: booking.startDate.toDate(),
          end: booking.endDate.toDate(),
          color: color,
          booking: booking,
          type: booking.type,
          displayType: 'location',
          bookingTime: bookingTime
        };
      });
    }));
  }

  getLocationBookings(locationId) {
    const bookingsCollection = this.afs.collection(`entities/${this.entityId}/locations/${locationId}/bookings`, ref => ref.where('active', '==', true));
    return bookingsCollection.snapshotChanges().pipe(map(changes => {
      return changes.map(a => {
        const booking = a.payload.doc.data() as any;
        let color;
        let bookingTime = 'future';
        if (booking.type === 'event') {
            color = colors.blue
        }
        if (booking.type === 'meetingRoom') {
            color = colors.yellow
        }
        if (booking.type === 'location') {
            color = colors.red
        }
        if (booking.status === 3) {
            color = colors.grey
        }
        if (new Date(booking.endDate.toDate()) < new Date()) {
          bookingTime = 'past';
        }
        this.afs.doc(`users/${booking.createdBy}`).ref.get().then(bookingUser => {
          if (bookingUser.exists) {
            booking.user = bookingUser.data();
          } else {
            booking.user = '';
          }
        });
        return {
            title: booking.name,
            start: booking.startDate.toDate(),
            end: booking.endDate.toDate(),
            color: color,
            booking: booking,
            type: booking.type,
            displayType: 'location',
            bookingTime: bookingTime
        };
      });
    }));
  }

  getMeetingRoomBookings(meetingRoomId) {
    const bookingsCollection = this.afs.collection(`entities/${this.entityId}/meetingRooms/${meetingRoomId}/bookings`, ref => ref.where('active', '==', true));
    return bookingsCollection.snapshotChanges().pipe(map(changes => {
      return changes.map(a => {
        const booking = a.payload.doc.data() as any;
        let color;
        let bookingTime = 'future';
        if (booking.type === 'event') {
            color = colors.blue
        }
        if (booking.type === 'meetingRoom') {
            color = colors.yellow
        }
        if (booking.type === 'location') {
            color = colors.red
        }
        if (booking.status === 3) {
            color = colors.grey
        }
        if (new Date(booking.endDate.toDate()) < new Date()) {
          bookingTime = 'past';
        }
        this.afs.doc(`users/${booking.createdBy}`).ref.get().then(bookingUser => {
          if (bookingUser.exists) {
            booking.user = bookingUser.data();
          } else {
            booking.user = '';
          }
        });
        return {
          title: booking.name,
          start: booking.startDate.toDate(),
          end: booking.endDate.toDate(),
          color: color,
          booking: booking,
          type: booking.type,
          displayType: 'meetingRoom',
          bookingTime: bookingTime
        };
      });
    }));
  }

  getUserBookings(userId) {
    const bookingsCollection = this.afs.collection(`users/${userId}/entities/${this.entityId}/bookings`, ref => ref.where('active', '==', true));
    return bookingsCollection.snapshotChanges().pipe(map(changes => {
      return changes.map(a => {
        const booking = a.payload.doc.data() as any;
        let color;
        let bookingTime = 'future';
        if (booking.type === 'event') {
            color = colors.blue
        }
        if (booking.type === 'meetingRoom') {
            color = colors.yellow
        }
        if (booking.type === 'location') {
            color = colors.red
        }
        if (booking.status === 3) {
            color = colors.grey
        }
        if (new Date(booking.endDate.toDate()) < new Date()) {
          bookingTime = 'past';
        }
        this.afs.doc(`users/${booking.createdBy}`).ref.get().then(bookingUser => {
          if (bookingUser.exists) {
            booking.user = bookingUser.data();
          } else {
            booking.user = '';
          }
        });
        return {
            title: booking.name,
            start: booking.startDate.toDate(),
            end: booking.endDate.toDate(),
            color: color,
            booking: booking,
            type: booking.type,
            displayType: 'user',
            bookingTime: bookingTime
        };
      });
    }));
  }

  addBooking(bookingData) {
    const bookingsCollection = this.afs.collection(`entities/${this.entityId}/bookings`);
    return bookingsCollection.add(bookingData);
  }

  updateBooking(data, uid, payment?) {
    const bookingsDoc = this.afs.doc(`entities/${this.entityId}/bookings/${uid}`);
    return bookingsDoc.set(data, { merge: true }).then(() => {
      if (payment) {
        return bookingsDoc.ref.get().then((booking: any) => {
          const bookingData = booking.data();

          const pendingNotifyData = {
            request: 'meetingRoomBookingNotify',
            source: config.source,
            entityId: config.entityId,
            bookingData: bookingData,
            user: this.loggedInUser
          }
          return Promise.resolve(pendingNotifyData).then(() => {
            return booking.data();
          })
        })
      }
    });
  }

  sendPendingNotify(data, message, type) {
    console.log(data, message, type);
    const pendingNotifyData = {
      request: 'bookingNotifyChange',
      source: config.source,
      entityId: config.entityId,
      bookingData: data,
      user: this.loggedInUser,
      type: type,
      message: message
    }
    return this.afs.collection(`pending`).add(pendingNotifyData);

  }

  // USER EVENTS
  addUserRsvpBooking(bookingData, userId) {
    const bookingsDoc = this.afs.doc(`users/${userId}/entities/${this.entityId}/bookings/${bookingData.typeId}`);
    const bookingDate = moment(bookingData.startDate.toDate()).format('DD MMMM YYYY');
    const bookingStart = moment(bookingData.startDate.toDate()).format('HH:mm');
    const bookingEnd = moment(bookingData.endDate.toDate()).format('HH:mm');

    // NOTIFICATIONS
    const notification = {
      title: 'Event RSVP Confirmation',
      body: `You have confirmed attendance for ${bookingData.name} for ${bookingDate} from ${bookingStart} to ${bookingEnd}`,
      type: 'eventBooking',
      createdBy: userId,
      addToHistory: true,
      userId: userId,
      customData: {
        createdBy: userId,
        notificationUrl: `/my-bookings`
      },
      historyInfo: {
        created: new Date(),
        title: 'Event RSVP Confirmation',
        message: `You have confirmed attendance for '<strong>${bookingData.name}</strong>' for <strong>${bookingDate}</strong> from <strong>${bookingStart}</strong> to <strong>${bookingEnd}</strong>`,
        linkText: `View Bookings`,
        type: 'eventBooking',
        createdBy: userId,
        userId: userId,
        url: `/my-bookings`,
        listRef: '/my-notifications',
        source: config.source,
        unread: true
      }
    };

    return bookingsDoc.set(bookingData, { merge: true }).then(() => {
      return this.notificationsService.addNotification(notification);
    });
  }

  getUserRsvpBookings(userId) {
    const bookingsCollection = this.afs.collection(`users/${userId}/entities/${this.entityId}/bookings`, ref => ref.where('active', '==', true).where('type', '==', 'event').where('attending', '==', true));
    return bookingsCollection.valueChanges();
  }

  createUserLocationBooking(startDate, endDate, location) {

    const booking = {
      active: true,
      price: 0,
      created: new Date(),
      createdBy: this.loggedInUser.uid,
      name: location.name,
      startDate: startDate,
      endDate: endDate,
      locationId: location.uid,
      locationRef: `entities/${config.entityId}/locations/${location.uid}`,
      locationName: location.name,
      type: 'location',
      typeId: location.uid,
      typeRef: `entities/${config.entityId}/locations/${location.uid}`,
      referencePrefix: `LOCATION-B-`,
      entityId: config.entityId,
      source: config.source,
      address: location.address,
      status: 2,
      checkedIn: false,
    }

    const pendingNotifyData = {
      request: 'locationBookingNotify',
      source: config.source,
      entityId: config.entityId,
      bookingData: booking,
      user: this.loggedInUser
    }

    const addBookingToEntity = this.afs.collection(`entities/${this.entityId}/bookings`);
    const pendingNotify = this.afs.collection(`pending`).add(pendingNotifyData);

    return addBookingToEntity.add(booking).then(ref => {
      const addBookingToUser = this.afs.doc(`users/${this.auth.userId}/entities/${this.entityId}/bookings/${ref.id}`).set(booking, { merge: true });
      return Promise.all([addBookingToUser, pendingNotify]);
    });

  }

  createCheckIn(locationData, bookingId, subscription) {
    const checkInsCollection = this.afs.collection(`entities/${this.entityId}/checkIns`);
    const locationCollection = this.afs.collection(`entities/${this.entityId}/locations/${locationData.uid}/checkIns`);
    const userCollection = this.afs.collection(`users/${this.auth.userId}/entities/${this.entityId}/checkIns`);
    const teamId = (subscription.type === 'teamSubscription') ? subscription.teamId : '';

    const checkInData = {
      active: true,
      created: new Date(),
      createdBy: this.loggedInUser.uid,
      createdByRef: this.loggedInUser.refNo,
      locationName: locationData.name,
      locationId: locationData.uid,
      locationRef: locationData.refNo,
      bookingId: bookingId,
      teamId: teamId,
      source: config.source
    }

    // NOTIFICATIONS
    let teamNotification;
    let sendTeamNotification;

    const userNotification = {
      title: 'You Have Checked Into a Location',
      body: `You have checked into ${locationData.name} at ${moment(new Date()).format('hh:mm a')}`,
      type: 'locationCheckIn',
      createdBy: this.loggedInUser.uid,
      addToHistory: true,
      userId: this.loggedInUser.uid,
      customData: {
        createdBy: this.loggedInUser.uid,
        notificationUrl: `/my-check-ins`,
      },
      historyInfo: {
        created: new Date(),
        title: 'You Have Checked Into a Location',
        message: `You have checked into '<strong>${locationData.name}</strong>' at ${moment(new Date()).format('hh:mm a')}`,
        linkText: `View Check Ins`,
        type: 'locationCheckIn',
        createdBy: this.loggedInUser.uid,
        userId: this.loggedInUser.uid,
        url: `/my-check-ins`,
        listRef: '/my-notifications',
        source: config.source,
        unread: true
      }
    }

    const sendLocationNotification = this.afs.doc(`entities/${this.entityId}/locations/${locationData.uid}`).ref.get().then((location: any) => {
      const locationInfo = location.data();
      const locationNotification = {
        title: 'Member Checked Into Your Location',
        body: `${this.loggedInUser.firstname} ${this.loggedInUser.surname} has checked into ${locationInfo.name} at ${moment(new Date()).format('hh:mm a')}`,
        type: 'userCheckIn',
        createdBy: this.loggedInUser.uid,
        addToHistory: true,
        userId: locationInfo.owner.uid,
        customData: {
          createdBy: this.loggedInUser.uid,
          notificationUrl: `/locations/edit/${locationInfo.uid}`,
        },
        historyInfo: {
          created: new Date(),
          title: 'Member Checked Into Your Location',
          message: `${this.loggedInUser.firstname} ${this.loggedInUser.surname} has checked into '<strong>${locationInfo.name}</strong>' at ${moment(new Date()).format('hh:mm a')}`,
          linkText: `View Location`,
          type: 'userCheckIn',
          createdBy: this.loggedInUser.uid,
          userId: locationInfo.owner.uid,
          url: `/locations/edit/${locationInfo.uid}`,
          listRef: '/my-notifications',
          source: config.source,
          unread: true
        }
      }
      this.notificationsService.addNotification(locationNotification);
    });

    const sendUserNotification = this.notificationsService.addNotification(userNotification);

    return checkInsCollection.add(checkInData).then(ref => {
      const saveToLocation = locationCollection.doc(ref.id).set(checkInData, {merge:true});
      const saveToUser = userCollection.doc(ref.id).set(checkInData, {merge:true});
      let teamCheckIn;
      if (subscription.type === 'teamSubscription') {
        teamCheckIn = this.afs.collection(`entities/${this.entityId}/teams/${subscription.teamId}/checkIns`).doc(ref.id).set(checkInData, {merge:true});
        sendTeamNotification = this.afs.doc(`entities/${this.entityId}/teams/${subscription.teamId}`).ref.get().then((team: any) => {
          const teamData = team.data();
          console.log(teamData)
          teamNotification = {
            title: 'Team Member Checked In',
            body: `${this.loggedInUser.firstname} ${this.loggedInUser.surname} has checked into ${locationData.name} at ${moment(new Date()).format('hh:mm a')}`,
            type: 'teamLocationCheckIn',
            createdBy: this.loggedInUser.uid,
            addToHistory: true,
            userId: teamData.owner.uid,
            customData: {
              createdBy: this.loggedInUser.uid,
              notificationUrl: `/teams/edit/${subscription.teamId}`,
            },
            historyInfo: {
              created: new Date(),
              title: 'Team Member Checked In',
              message: `${this.loggedInUser.firstname} ${this.loggedInUser.surname} has checked into '<strong>${locationData.name}</strong>' at ${moment(new Date()).format('hh:mm a')}`,
              linkText: `View Team`,
              type: 'teamLocationCheckIn',
              createdBy: this.loggedInUser.uid,
              userId: teamData.owner.uid,
              url: `/teams/edit/${subscription.teamId}`,
              listRef: '/my-notifications',
              source: config.source,
              unread: true
            }
          }
          this.notificationsService.addNotification(teamNotification);
        });
      }
      return Promise.all([saveToLocation, saveToUser, sendUserNotification, sendLocationNotification, teamCheckIn, sendTeamNotification]);
    })
  }

  deleteCheckIn(checkIn) {
    const pendingCollection = this.afs.collection(`pending`);
    const checkInData = {
      request: 'deleteCheckIn',
      source: config.source,
      entityId: config.entityId,
      userId: this.loggedInUser.uid,
      checkInData: checkIn,
      checkInId: checkIn.id
    }
    return pendingCollection.add(checkInData);
  }

  getCheckIns(type) {
    const checkInsCollection = this.afs.collection(`${type}/checkIns`, ref => ref.where('active', '==', true).orderBy('created', 'desc'));
    return checkInsCollection.valueChanges({ idField: 'id' });
  }

}
