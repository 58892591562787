import { Injectable } from "@angular/core";
import { BehaviorSubject, Subject, Observable } from "rxjs";
import { User } from "src/app/theme/pages/wealth-spaces/users/_models/user.model";

@Injectable({
  providedIn: "root", // <--provides this service in the root ModuleInjector
})
export class StorageService {
  // USER DETAILS
  public userDetails = new BehaviorSubject<User>({});

  // LOCATIONS
  public locations = new BehaviorSubject<any[]>([]);
  public mapLocations = new BehaviorSubject<any[]>([]);

  // SINGLE LOCATION
  public singleLocation = new BehaviorSubject<any>({});

  // LOCATIONS FILTER
  public locationFilter = new BehaviorSubject<any>({});

  // MODALS
  public modal = new BehaviorSubject<any>({});
  currentModal = this.modal.asObservable();

  // PRODUCTS
  public product = new BehaviorSubject<any>({});

  // SUBSCRIPTIONS
  public subscription = new BehaviorSubject<any>({});

  constructor() {}

  // MODALS
  openModal(modal: any) {
    return this.modal.next(modal);
  }

  // PRODUCTS
  selectedProduct(product: any) {
    return this.product.next(product);
  }
}
